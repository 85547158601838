@import '../../styles/variables.scss';

.modalContainer {
  position: fixed;
  z-index: 1000;
  background-image: $model-gradient;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px) grayscale(100%); //only works in Safari and other webkit browsers for now
}

.modalBox {
  width: 750px;
  height: 580px;
  opacity: 0.9;
  border-radius: 20px;
  background-color: $nfx-navy;
}
