.qrcodeWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.qrcodeTitle {
  width: 462px;
  height: 67px;
  font-family: NiveauGrotesk;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.qrcodeWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.remoteUrl {
  word-wrap: break-word;
  color: white;
}

@media screen and (max-width: 1920px) {
  .qrcodeTitleAdditional {
    font-size: 30px;
  }
}

@media screen and (max-width: 960px) {
  .qrcodeTitleAdditional {
    font-size: 27px;
  }
}
