@import '../../styles/variables.scss';

.branding {
  position: fixed;
  top: 20px;
  left: 20px;

  & .subtext {
    color: $nfx-white;
    font-size: 1.3em;
  }
}

.endEvent {
  position: fixed;
  font-size: 3em;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  color: $nfx-white;
  display: none;
  opacity: 1;
  transition: opacity 2s;
  z-index: 185;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .logo {
    padding: 0;
    margin-bottom: 30px;
  }

  & .thanksText {
    color: $nfx-white;
    font-size: 0.8em;
    margin-bottom: 30px;
  }

  & .completedButton {
    margin: 10px;
    border: 2px solid $nfx-white;
    background: $nfx-navy;
    border-radius: 10px;
    padding: 10px 40px;
    transition: 0.25s;
    cursor: pointer;

    &.active {
      border: 2px solid $nfx-white;
      background: $nfx-white;
      color: $nfx-navy;
    }

    &:hover {
      border: 2px solid $nfx-white;
      background: $nfx-white;
      color: $nfx-navy;
    }
  }

  & .countdown {
    width: 50px;
    height: 50px;
  }
}
