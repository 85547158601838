@import '../../styles/variables.scss';

.backgroundImage {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 0;
}

.detailsText {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 25vh;
  color: $nfx-white;
  font-size: 1.4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .title {
    font-weight: 600;
    line-height: 2;
    color: $nfx-white;
    font-size: 2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .credits {
    font-weight: 600;
    line-height: 0.92;
    color: $nfx-white;
    font-size: 0.8em;
  }

  & .description {
    color: $nfx-white;
    font-size: 1.4em;
    width: 60vw;
  }

  @media screen and (max-width: 1680px) {
    & .titleAdditional {
      font-size: 1.2em;
    }

    & .descriptionAdditional {
      font-size: 1.0em;
    }
  }

  @media screen and (max-width: 960px) {
    & .titleAdditional {
      font-size: 1em;
    }

    & .descriptionAdditional {
      font-size: 0.4em;
    }
  }
}
