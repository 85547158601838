@import '../../styles/variables.scss';

.buttonGroup {
  position: relative;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  display: inline-flex;
  vertical-align: middle;
  &.button {
    &:first-child {
      margin-left: 0;
    }
  }

  &.wide {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.dropdownMenu {
  position: absolute;
  top: calc(100% - 3px);
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.12rem 0 0;
  font-size: 1rem;
  color: $color-black;
  text-align: left;
  list-style: none;
  background-color: $color-white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;

  &.active {
    display: block;
  }
}

.dropdownItem {
  display: block;
  padding: 3px 1.5rem;
  clear: both;
  font-weight: 400;
  color: $color-black;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
  cursor: pointer;
  background-color: $color-white;

  &:hover {
    background-color: shade($color-white, 10%);
  }

  &a {
    touch-action: manipulation;
    text-decoration: none;
  }
}
