.currentId {
  color: #f0f0f0;
  display: none;
}
.videoWrapper {
  /* position: relative;
  display: flex; */
  color: #fff;
}
.videoContainer {
  z-index: 50;
  width: 100vw;
  background-color: #666;
}

.videoContainer.hidden {
  display: none;
}

.videoContainer .urlText {
  position: absolute;
  top: 5px;
  left: 3px;
  right: 3px;
  color: #fff;
  font-size: 9pt;
  word-wrap: break-word;
  text-align: left;
  display: inline;
}

.videostatus {
  display: flex;
}
.statusItem {
  color: #f0f0f0;
  opacity: 1;
  transition: opacity 500ms;
  padding: 10px 10px;
  display: none;

  & .hidden {
    opacity: 0.5;
  }
}

.videoPlayer {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 50;

  &.hidden {
    z-index: 45;
  }
}
