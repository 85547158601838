@import '../../styles/variables.scss';

.App {
  text-align: center;
}

.Pairing {
  background-color: $nfx-darkgrey;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NFXlogoContainer {
  background-color: $nfx-navy;
  border-radius: 2em;
  padding: 50px;
  margin: 10px;
  text-align: center;
}

.loadingContainer {
  background-color: $nfx-navy;
  border-radius: 2em;
  padding: 50px;
  margin: 10px;
  text-align: center;
}

.NFXlogo {
  /* animation: App-logo-spin infinite 50s linear; */
  width: 50vw;
}

.helpText {
  margin: 16px;
  font-size: 2.5vw;
  color: #f0f0f0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
