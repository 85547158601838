@import '../../styles/variables.scss';

.topBar {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 60px;
  background-color: $nfx-navy;
  z-index: 10000;
  opacity: 0.8;
  transition: 0.5s;
  overflow: hidden;

  &.hidden {
    // height: 0px;
    top: -60px;
    opacity: 0;
  }

  & .items {
    padding: 10px;
    width: calc(100vw -20px);
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  & .branding {
    position: absolute;
    left: 0;
    right: 0;
  }

  & .back {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    & .backLabel {
      padding: 0 6px;
    }
  }

  & .exitButton {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-width: 3px;
    stroke-miterlimit: 10;
  }

  & .rightHand {
    display: flex;
    z-index: 100;

    & .actionItem {
      cursor: pointer;
      padding: 0 6px;
      width: 50px;

      & .label {
        font-size: 0.8em;
        color: $nfx-white;

        &.active {
          color: $nfx-blue;
        }
      }

      & .icon {
        height: 33px;
        margin: auto;
        width: 50%;
      }

      & .shareIcon {
        fill: none;
        stroke: #fff;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
        stroke-width: 3px;

        &.active {
          stroke: $nfx-blue;
        }
      }

      .cls1,
      .cls2,
      .cls5 {
        fill: none;
      }
      .cls1,
      .cls2,
      .cls3 {
        stroke: #fff;

        &.active {
          stroke: $nfx-blue;
        }
      }
      .cls1,
      .cls2 {
        stroke-width: 3px;
      }
      .cls1 {
        stroke-miterlimit: 10;
      }
      .cls2 {
        stroke-linecap: round;
      }
      .cls3 {
        fill: #fff;
      }
      .cls4 {
        stroke: none;
      }

      .settingsIcon {
        fill: #fff;
        stroke: #fff;

        &.settingsLine {
          fill: none;
          stroke: #fff;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 3px;
        }

        &.active {
          fill: $nfx-blue;
          stroke: $nfx-blue;
        }
      }
    }
  }
}
