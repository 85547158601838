@import '../../styles/variables.scss';

.rating {
  & .questionText {
    color: $nfx-white;
    font-size: 0.8em;
    margin-bottom: 30px;
  }
  & .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    & .completedButton {
      margin: 10px;
      border: 2px solid $nfx-white;
      background: $nfx-navy;
      border-radius: 10px;
      padding: 10px 40px;
      transition: 0.25s;
      cursor: pointer;

      &.active {
        border: 2px solid $nfx-white;
        background: $nfx-white;
        color: $nfx-navy;
      }

      &:hover {
        border: 2px solid $nfx-white;
        background: $nfx-white;
        color: $nfx-navy;
      }
    }
  }

  & .thumbContainter {
    margin: 15px;

    &.hidden {
      display: none;
    }
  }

  & .thumb {
    fill: none;
    stroke: $nfx-white;
    stroke-miterlimit: 10;
    stroke-width: 3px;

    &.active {
      fill: $nfx-white;
    }

    &.selected {
      fill: $nfx-blue;
      stroke: none;
    }
  }
}
