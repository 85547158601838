@import '../../styles/variables.scss';

.Content {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.ContentNotReady {
  display: none
}

.resourceList {
  width: 100vw;
  padding: 0px;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  color: $nfx-white;
  flex-flow: row nowrap;
}
