@import '../../styles/variables.scss';

/* form starting stylings ------------------------------- */
.group {
  position: relative;
  margin-bottom: 5px;
  width: 100%;
  margin-right: 10px;
  margin-top: 26px;

  &.size--small {
    margin-bottom: 0;
  }
}

.customInput {
  font-size: 1em;
  padding: 10px 3px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-ne-grey);

  &:disabled {
    cursor: not-allowed;
    background-color: var(--color-ne-grey);
    color: var(--color-ne-grey);
  }

  &:focus {
    outline: none;
  }

  /* active state */
  &:focus ~ .bar:before,
  &:focus ~ .bar:after {
    width: 50%;
  }
}

.customInput:focus ~ .highlight {
  animation: inputHighlighter 0.3s ease;
}
.customInput:-webkit-autofill,
.customInput:-webkit-autofill:hover,
.customInput:-webkit-autofill:focus {
  background-color: var(--color-white) !important;
}

.customInput:valid ~ label {
  top: -20px;
  left: 0;
  font-size: 14px;
}

.customInput:focus ~ label {
  top: -20px;
  left: 0;
  font-size: 14px;
  color: var(--color-ne-green);
}

.size--small > .customInput {
  font-size: 0.8em;
  padding: 4px 0;
  margin: 0;
  border: none;
}

.theme--light > .customInput {
  background-color: var(--color-white);
}

.theme--dark > .customInput {
  background-color: var(--color-offblack);
  color: var(--color-offwhite);
}

/* LABEL ======================================= */
label {
  color: var(--color-gray-medium);
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
}

/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: 100%;
}
.bar:before,
.bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: var(--color-ne-green);
  transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

/* ICON ================================== */
.icon {
  position: absolute;
  top: 1px;
  right: 6px;
  pointer-events: none;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* ANIMATIONS ================ */
@keyframes inputHighlighter {
  from {
    background: var(--color-ne-green);
  }
  to {
    width: 0;
    background: transparent;
  }
}
