.menuContainer {
  position: fixed;
  top: 5px;
  right: 5px;
  color: rgba(255, 255, 255, 0.8);
  opacity: 0.2;
  text-align: right;
  transition: opacity 1s;
  z-index: 10000;

  &:hover {
    opacity: 1;
  }

  .hidden {
    opacity: 0;
  }

  .burgerContainer {
    display: inline-block;
    cursor: pointer;
  }
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: rotate(-45deg) translate(-8px, 8px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: rotate(45deg) translate(-8px, -8px);
}

.menuItems ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menuItems ul li {
  padding: 5px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

.menuItems ul li:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
