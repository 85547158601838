@import '../../styles/variables.scss';

.drawer {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30vw;
  opacity: 0.9;
  background-color: $nfx-navy;
  transition: 0.5s;
  overflow: hidden;
  z-index: 9000;

  &.active {
    width: 0;
    opacity: 0;
  }
}
