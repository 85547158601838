@import '../../styles/variables.scss';

.Content {
  background-color: $nfx-navy;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.branding {
  position: fixed;
  top: 0;
  left: 0;

  & .logo {
    width: 10vw;
    height: auto;
    padding: 2vh;
  }
}

.resourceList {
  padding: 0px;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  color: $nfx-white;
  flex-flow: row nowrap;
  overflow: hidden;
}

.contentItem {
  width: 17vw;
  min-width: 160px;
  min-height: 200px;
  // height: 20vw;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 20px;
  transition: 500ms;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }

  &.selected {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.imageContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15vw;
  min-width: 150px;
  height: 15vw;
  min-height: 150px;
  overflow: hidden;
}

.mediaImage {
  width: 100%;
  height: auto;
}

.title {
  line-height: 2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loadingWrap {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
