.imageViewer {
  position: relative;
}
.imageContainer {
  position: absolute;
  opacity: 1;
  transition: opacity 1500ms;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
}

.imageContainer.hidden {
  opacity: 0;
  z-index: 15;
}
