@import '../../styles/variables.scss';

.shareDrawer {
  margin-top: 100px;
  color: $nfx-white;
  text-align: left;
  width: calc(25vw - 60px);
  padding: 0 30px;

  & h3 {
    margin-top: 26px;
    margin-bottom: 2px;
  }

  & a {
    color: $nfx-blue;
    text-decoration: none;
  }

  & .shareItem {
    padding: 10px 0;
  }
}
