.currentId {
  color: #f0f0f0;
  display: none;
}
.videoWrapper {
  width: 100vw;
  height: 100%;
  &.hidden {
    display: none;
  }
}
.videoContainer {
  z-index: 50;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.hidden {
    display: none;
  }

  .urlText {
    position: absolute;
    top: 5px;
    left: 3px;
    right: 3px;
    color: #fff;
    font-size: 9pt;
    word-wrap: break-word;
    text-align: left;
    display: none;
  }
}

.videostatus {
  display: flex;
}
.statusItem {
  color: #f0f0f0;
  opacity: 1;
  transition: opacity 500ms;
  padding: 10px 10px;
  display: none;

  &.hidden {
    opacity: 0.5;
  }
}

.downloadingVideo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
