@import '../../styles/variables.scss';

.volumeControl {
  display: flex;

  &.disabled {
    cursor: not-allowed;
  }

  & .speaker {
    padding: 0 16px;
  }

  & .sliderWrapper {
    position: relative;
    width: 10vw;

    & .slider {
      left: 0;
      right: 0;
      top: 10px;
      position: absolute;
      -webkit-appearance: none;
      width: 100%;
      height: 6px;
      border-radius: 5px;
      // background: $nfx-white;
      background: transparent;
      outline: none;
      opacity: 1;

      &.disabled {
        cursor: not-allowed;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: $nfx-white;
        cursor: pointer;

        // background: $nfx-white;
        // box-shadow: -100vw 0 0 100vw $nfx-white;
        // border: 2px solid #999; /* 1 */
      }

      // &::-webkit-slider-runnable-track {
      //   background: $nfx-darkgrey;
      // }

      &::-moz-range-thumb {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: $nfx-white;
        cursor: pointer;
      }
    }

    & .progress {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      top: 12px;
      height: 6px;
      background-color: $nfx-darkgrey;

      &.disabled {
        cursor: not-allowed;
      }

      &::-webkit-progress-bar {
        background-color: $nfx-darkgrey;
      }

      &::-webkit-progress-value {
        background-color: $nfx-white;
      }
    }
  }
}
