.contentItem {
  width: 17vw;
  min-width: 160px;
  min-height: 200px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 2px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }

  &.selected {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.imageContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15vw;
  min-width: 150px;
  height: 15vw;
  min-height: 150px;
  overflow: hidden;
}

.mediaImage {
  width: 100%;
  height: auto;
}

.title {
  line-height: 2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loadingWrap {
  text-align: center;
  width: 17vw;
  min-width: 160px;
  min-height: 200px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
