@import '../../styles/variables.scss';

.settingsDrawer {
  margin-top: 100px;
  color: $nfx-white;
  text-align: left;
  width: calc(25vw - 60px);
  padding: 0 30px;

  & h3 {
    margin-top: 26px;
    margin-bottom: 2px;
  }

  & a {
    color: $nfx-orange;
  }

  & .qrcodeWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  & .remoteUrl {
    word-wrap: break-word;
  }

  & .playText {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 12px 0;
    cursor: pointer;

    & .playIconContainer {
      padding-right: 6px;
    }

    & .playIcon {
      fill: none;
      stroke: $nfx-orange;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 3px;

      &.active {
        fill: $nfx-orange;
        animation-name: pulsate;
        animation-iteration-count: infinite;
        animation-duration: 1.5s;
      }
    }

    & .playLabel {
      color: $nfx-orange;

      &.active {
        color: $nfx-white;
        animation-name: pulsate;
        animation-iteration-count: infinite;
        animation-duration: 1.5s;
      }
    }
  }
}

@keyframes pulsate {
  0% {
    color: $nfx-white;
    stroke: $nfx-white;
  }
  50% {
    color: $nfx-orange;
    stroke: $nfx-orange;
  }
  100% {
    color: $nfx-white;
    stroke: $nfx-white;
  }
}
