@import '../../styles/variables.scss';

.currentId {
  color: #f0f0f0;
  display: none;
}
.videoWrapper {
  /* display: flex; */
}
.videoContainer {
  position: relative;
  z-index: 50;
  width: 100%;
}

.videoContainer.hidden {
  display: none;
}

.videoContainer .urlText {
  position: absolute;
  top: 5px;
  left: 3px;
  right: 3px;
  color: #fff;
  font-size: 9pt;
  word-wrap: break-word;
  text-align: left;
  display: none;
}

.stbVideo {
  overflow: hidden;
  position: absolute;
  opacity: 1;
  transition: opacity 1500ms;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
}
.videoPlayer {
  position: absolute;
  opacity: 1;
  transition: opacity 1500ms;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
}

.imagePlaceholder {
  position: absolute;
  opacity: 1;
  transition: opacity 1500ms;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 15;
}

.videostatus {
  display: flex;
}
.statusItem {
  color: #f0f0f0;
  opacity: 1;
  transition: opacity 500ms;
  padding: 10px 10px;
  display: none;
}

.statusItem.hidden {
  opacity: 0.5;
}

.debug {
  position: absolute;
  font-size: 2em;
  top: 0;
  height: 20px;
  left: 0;
  right: 0;
  background-color: (255, 255, 255, 0.5);
  z-index: 50;
}
