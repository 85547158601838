@font-face {
  font-family: 'New Era Casual Bold Italic';
  src: url('./assets/fonts/new_era_casual_bold_italic-webfont.woff2') format('woff2'),
    url('./assets/fonts/new_era_casual_bold_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Era Casual Bold';
  src: url('./assets/fonts/new_era_casual_bold-webfont.woff2') format('woff2'), url('./assets/fonts/new_era_casual_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Era Casual Italic';
  src: url('./assets/fonts/new_era_casual_italic-webfont.woff2') format('woff2'), url('./assets/fonts/new_era_casual_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Era Casual';
  src: url('./assets/fonts/new_era_casual_regular-webfont.woff2') format('woff2'), url('./assets/fonts/new_era_casual_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} 

.App {
  text-align: center;
  background-color: #000;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
