@import '../../styles/variables.scss';

.mediascape {
  color: #fff;
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: $nfx-navy;
}

.foo {
  width: 10px;
}

.bookText {
  width: 50vw;
  text-align: left;
  line-height: 1.5em;
  height: 20vh;
  overflow: auto;
}

.loading {
  color: #fff;
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gettingSpeechReady {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.menubar {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.5);
}

.videoSplash {
  background-color: $nfx-navy;
  height: 100%;
  width: 100%;
  background-image: linear-gradient($nfx-blue, $nfx-navy);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
  &.hidden {
    display: none;
  }
}

.playButton {
  position: absolute;
  height: 12vw;
  width: 12vw;
  left: 50%;
  margin-left: -6vw;
  top: 50%;
  margin-top: -6vw;
}

.waitforSpeech {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: $nfx-navy;
}
// background-color: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8));
.prepText {
  font-size: 2em;
  margin-bottom: 2em;
}
.fadeIn {
  animation: fadein 3s;
  color: #fff;
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
