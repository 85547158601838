@import '../../styles/variables.scss';

.aboutDrawer {
  margin-top: 100px;
  color: $nfx-white;
  text-align: left;
  width: calc(25vw - 60px);
  padding: 0 30px;

  & h3 {
    margin-top: 26px;
    margin-bottom: 2px;
  }

  & a {
    color: $nfx-orange;
  }
}
