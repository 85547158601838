@import '../../styles/variables.scss';
.contentItem {
  opacity: 0.75;
  transition: all 100ms ease-in-out;
  display: none;

  & .imageContainer {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20vw;
    min-width: 150px;
    height: auto;
    // min-height: 150px;
    overflow: hidden;
    transition: all 500ms ease-out;

    & img {
      display: block;
    }
  }

  & .title {
    font-size: 2em;
    line-height: 2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none;
  }

  & .description {
    font-size: 1.4em;
    display: none;
  }

  &.selected {
    z-index: 100;
    opacity: 1;
    order: 2;
    display: block;

    z-index: 3;
    transform: scale(2.5);
    // box-shadow: 15px 0px 20px 0px $nfx-navy, -15px 0px 20px 0px $nfx-navy;

    & .imageContainer {
      // width: 45vw;
      box-shadow: 0 0 5px 1px $nfx-blue, /* inner white */ 0 0 5px 1px $nfx-blue, /* middle magenta */ 0 0 5px 1px $nfx-blue; /* outer cyan */
    }

    & .title {
      display: block;
    }

    & .description {
      display: block;
    }
  }

  &.left {
    // margin-right: -120px;
    order: 1;
    display: block;

    transform: translateX(-280px) scale(1.5);
    z-index: 1;
  }

  &.right {
    // margin-left: -120px;
    order: 3;
    display: block;
    transform: translateX(280px) scale(1.5);
    z-index: 2;
  }
}

.mediaImage {
  width: 100%;
  height: auto;
}

.loadingWrap {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
