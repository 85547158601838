@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700);
@import url(https://use.typekit.net/lki7ztn.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.AudioPlayer_audioplayer__oevww {
  display: none; }

.ImageViewer_imageViewer__2uUMi {
  position: relative;
}
.ImageViewer_imageContainer__12bjJ {
  position: absolute;
  opacity: 1;
  -webkit-transition: opacity 1500ms;
  transition: opacity 1500ms;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
}

.ImageViewer_imageContainer__12bjJ.ImageViewer_hidden__m125n {
  opacity: 0;
  z-index: 15;
}

.RandomCode_Large__2bpV0 {
  color: #fff;
  font-size: 10vw;
}
.ProgressBar_UnityLoading__7fIwL {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #222;
  color: #f0f0f0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProgressBar_FullBar__3j1wu {
  position: relative;
  width: 80vw;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.ProgressBar_LoadingAmount__3-KYI {
  border-radius: 30px;
  color: #fff !important;
  height: 5vh;
  background-color: rgba(255, 255, 255, 0.8) !important;
  justify-content: left;
}

.ProgressBar_LoadingNumber__2piP3 {
  color: #222;
  position: absolute;
  left: 16px;
  top: 16px;
  font-size: 2vh;
}

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.ScreenText_textPlacement__ofNSr {
  margin-left: auto;
  margin-right: auto; }

.ScreenText_TextContainer__3Q4wv {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  -webkit-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  z-index: 100;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px); }
  .ScreenText_TextContainer__3Q4wv.ScreenText_shelvesVisible__2oyMh {
    bottom: 80px; }
    .ScreenText_TextContainer__3Q4wv.ScreenText_shelvesVisible__2oyMh:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-top-color: rgba(0, 0, 0, 0.4);
      border-bottom: 0;
      margin-left: -20px;
      margin-bottom: -20px; }
  .ScreenText_TextContainer__3Q4wv.ScreenText_visible__3IPNh {
    opacity: 1;
    -webkit-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out; }

.ScreenText_TextContainer__3Q4wv .ScreenText_readingWords__2EE45 {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in; }

.ScreenText_TextContainer__3Q4wv.ScreenText_visible__3IPNh .ScreenText_readingWords__2EE45 {
  opacity: 1; }

.ScreenText_TextContainer__3Q4wv.ScreenText_enchant__37Kyb {
  font-family: 'New Era Casual'; }

.ScreenText_TextGroup__joYKN {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
  width: 100%; }

.ScreenText_readingWords__2EE45 {
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  padding: 1.5vw;
  width: 40vw;
  height: 4.3vw;
  display: flex;
  flex-wrap: wrap;
  font-size: 2.1vw;
  line-height: 1.5em;
  text-shadow: 0px 0px 9px black;
  margin-bottom: 0;
  -webkit-transition: all 600ms ease-out;
  transition: all 600ms ease-out;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }
  .ScreenText_readingWords__2EE45 .ScreenText_wordItem__2bp4T {
    margin-right: 0.8vw;
    -webkit-transition: all 0.1s;
    transition: all 0.1s; }
    .ScreenText_readingWords__2EE45 .ScreenText_wordItem__2bp4T.ScreenText_activeWord__2yGJC {
      color: #fffacc;
      text-shadow: -2px -2px 5px #4bb6c2, 2px -2px 5px #4bb6c2, -2px 2px 5px #4bb6c2, 2px 2px 5px #4bb6c2; }
    .ScreenText_readingWords__2EE45 .ScreenText_wordItem__2bp4T.ScreenText_nextWord__2ylnt {
      text-shadow: 0px 0px 9px black; }
    .ScreenText_readingWords__2EE45 .ScreenText_wordItem__2bp4T.ScreenText_readWord__1bNf7 {
      text-shadow: -2px -2px 5px #4bb6c2, 2px -2px 5px #4bb6c2, -2px 2px 5px #4bb6c2, 2px 2px 5px #4bb6c2; }
    .ScreenText_readingWords__2EE45 .ScreenText_wordItem__2bp4T.ScreenText_usesSeparator__3hVvu {
      margin-right: 0; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.VideoManager_videoManager__1pjSG {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ffffff; }

.VideoManager_videoMediaScape__2hlWF {
  height: 100%; }

.VideoManager_completedMenu__13pFi {
  position: fixed;
  font-size: 3em;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
  z-index: 185; }
  .VideoManager_completedMenu__13pFi.VideoManager_active__2EIhX {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1; }
  .VideoManager_completedMenu__13pFi .VideoManager_completedButton__2fx1R {
    margin: 10px;
    border: 2px solid #ffffff;
    background: #1a2c38;
    border-radius: 10px;
    padding: 10px 40px;
    -webkit-transition: 0.25s;
    transition: 0.25s;
    cursor: pointer; }
    .VideoManager_completedMenu__13pFi .VideoManager_completedButton__2fx1R.VideoManager_active__2EIhX {
      border: 2px solid #ffffff;
      background: #ffffff;
      color: #1a2c38; }
    .VideoManager_completedMenu__13pFi .VideoManager_completedButton__2fx1R:hover {
      border: 2px solid #ffffff;
      background: #ffffff;
      color: #1a2c38; }

.VideoController_currentId__29wRq {
  color: #f0f0f0;
  display: none; }

.VideoController_videoWrapper__a6K_P {
  height: 100%; }

.VideoController_videoContainer__28r0t {
  z-index: 50;
  width: 100vw; }
  .VideoController_videoContainer__28r0t.VideoController_hidden__3Jlhg {
    display: none; }
  .VideoController_videoContainer__28r0t .VideoController_urlText__3sUNI {
    position: absolute;
    top: 5px;
    left: 3px;
    right: 3px;
    color: #fff;
    font-size: 9pt;
    word-wrap: break-word;
    text-align: left;
    display: none; }

.VideoController_videostatus__eJQ7z {
  display: flex; }

.VideoController_statusItem__3a9UU {
  color: #f0f0f0;
  opacity: 1;
  -webkit-transition: opacity 500ms;
  transition: opacity 500ms;
  padding: 10px 10px;
  display: inline; }
  .VideoController_statusItem__3a9UU.VideoController_hidden__3Jlhg {
    opacity: 0.5; }

.VideoPlayer_currentId__1ZjJG {
  color: #f0f0f0;
  display: none; }

.VideoPlayer_videoWrapper__15NcF {
  width: 100vw;
  height: 100%; }
  .VideoPlayer_videoWrapper__15NcF.VideoPlayer_hidden__31UTe {
    display: none; }

.VideoPlayer_videoContainer__duY7z {
  z-index: 50;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .VideoPlayer_videoContainer__duY7z.VideoPlayer_hidden__31UTe {
    display: none; }
  .VideoPlayer_videoContainer__duY7z .VideoPlayer_urlText__2RsfD {
    position: absolute;
    top: 5px;
    left: 3px;
    right: 3px;
    color: #fff;
    font-size: 9pt;
    word-wrap: break-word;
    text-align: left;
    display: none; }

.VideoPlayer_videostatus__1tSEV {
  display: flex; }

.VideoPlayer_statusItem__3bUtx {
  color: #f0f0f0;
  opacity: 1;
  -webkit-transition: opacity 500ms;
  transition: opacity 500ms;
  padding: 10px 10px;
  display: none; }
  .VideoPlayer_statusItem__3bUtx.VideoPlayer_hidden__31UTe {
    opacity: 0.5; }

.VideoPlayer_downloadingVideo__JiBI5 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.VideoPlayerSimple_currentId__pP8xm {
  color: #f0f0f0;
  display: none; }

.VideoPlayerSimple_videoWrapper__1ukvM {
  /* position: relative;
  display: flex; */
  color: #fff; }

.VideoPlayerSimple_videoContainer__1n2ah {
  z-index: 50;
  width: 100vw;
  background-color: #666; }

.VideoPlayerSimple_videoContainer__1n2ah.VideoPlayerSimple_hidden__3fjZo {
  display: none; }

.VideoPlayerSimple_videoContainer__1n2ah .VideoPlayerSimple_urlText__2FwKu {
  position: absolute;
  top: 5px;
  left: 3px;
  right: 3px;
  color: #fff;
  font-size: 9pt;
  word-wrap: break-word;
  text-align: left;
  display: inline; }

.VideoPlayerSimple_videostatus__1KxvR {
  display: flex; }

.VideoPlayerSimple_statusItem__3YEYY {
  color: #f0f0f0;
  opacity: 1;
  -webkit-transition: opacity 500ms;
  transition: opacity 500ms;
  padding: 10px 10px;
  display: none; }
  .VideoPlayerSimple_statusItem__3YEYY .VideoPlayerSimple_hidden__3fjZo {
    opacity: 0.5; }

.VideoPlayerSimple_videoPlayer__33sJL {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 50; }
  .VideoPlayerSimple_videoPlayer__33sJL.VideoPlayerSimple_hidden__3fjZo {
    z-index: 45; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.VideoPlayer_currentId__3p8Oh {
  color: #f0f0f0;
  display: none; }

.VideoPlayer_videoWrapper__3lX6T {
  /* display: flex; */ }

.VideoPlayer_videoContainer__3JILX {
  position: relative;
  z-index: 50;
  width: 100%; }

.VideoPlayer_videoContainer__3JILX.VideoPlayer_hidden__1U9DM {
  display: none; }

.VideoPlayer_videoContainer__3JILX .VideoPlayer_urlText__2qKRC {
  position: absolute;
  top: 5px;
  left: 3px;
  right: 3px;
  color: #fff;
  font-size: 9pt;
  word-wrap: break-word;
  text-align: left;
  display: none; }

.VideoPlayer_stbVideo__3EA1p {
  overflow: hidden;
  position: absolute;
  opacity: 1;
  -webkit-transition: opacity 1500ms;
  transition: opacity 1500ms;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20; }

.VideoPlayer_videoPlayer__2_8tP {
  position: absolute;
  opacity: 1;
  -webkit-transition: opacity 1500ms;
  transition: opacity 1500ms;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20; }

.VideoPlayer_imagePlaceholder__2Xaz0 {
  position: absolute;
  opacity: 1;
  -webkit-transition: opacity 1500ms;
  transition: opacity 1500ms;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 15; }

.VideoPlayer_videostatus__1zH7w {
  display: flex; }

.VideoPlayer_statusItem__2isVt {
  color: #f0f0f0;
  opacity: 1;
  -webkit-transition: opacity 500ms;
  transition: opacity 500ms;
  padding: 10px 10px;
  display: none; }

.VideoPlayer_statusItem__2isVt.VideoPlayer_hidden__1U9DM {
  opacity: 0.5; }

.VideoPlayer_debug__37DKJ {
  position: absolute;
  font-size: 2em;
  top: 0;
  height: 20px;
  left: 0;
  right: 0;
  background-color: 255, 255, 255, 0.5;
  z-index: 50; }

.AudioManager_audioManager__TRDg3 {
  position: fixed;
  top: 0;
  left: 0;
  color: #fff;
  opacity: 0.5; }

.AudioManager_audioManager__11VUL {
  position: fixed;
  top: 0;
  left: 0;
  color: #fff;
  opacity: 0;
}

.BrowserSizeManager_videoSizeEval__1gKPi {
  height: 100%; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Loading_ldsRipple__25x6z {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }
  .Loading_ldsRipple__25x6z div {
    position: absolute;
    border: 4px solid #4bb6c2;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: Loading_lds-ripple__17L-T 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: Loading_lds-ripple__17L-T 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
    .Loading_ldsRipple__25x6z div:nth-child(2) {
      -webkit-animation-delay: -0.5s;
              animation-delay: -0.5s; }

@-webkit-keyframes Loading_lds-ripple__17L-T {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0; } }

@keyframes Loading_lds-ripple__17L-T {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0; } }

.Menu_menuContainer__jsKDc {
  position: fixed;
  top: 5px;
  right: 5px;
  color: rgba(255, 255, 255, 0.8);
  opacity: 0.2;
  text-align: right;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  z-index: 10000; }
  .Menu_menuContainer__jsKDc:hover {
    opacity: 1; }
  .Menu_menuContainer__jsKDc .Menu_hidden__23OJA {
    opacity: 0; }
  .Menu_menuContainer__jsKDc .Menu_burgerContainer__1WHU9 {
    display: inline-block;
    cursor: pointer; }

.Menu_bar1__3xhbJ,
.Menu_bar2__tChIa,
.Menu_bar3__1YaF8 {
  width: 35px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 6px 0;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.Menu_change__EeLmh .Menu_bar1__3xhbJ {
  -webkit-transform: rotate(-45deg) translate(-8px, 8px);
          transform: rotate(-45deg) translate(-8px, 8px); }

.Menu_change__EeLmh .Menu_bar2__tChIa {
  opacity: 0; }

.Menu_change__EeLmh .Menu_bar3__1YaF8 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
          transform: rotate(45deg) translate(-8px, -8px); }

.Menu_menuItems__1PeN0 ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.Menu_menuItems__1PeN0 ul li {
  padding: 5px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer; }

.Menu_menuItems__1PeN0 ul li:hover {
  background-color: rgba(255, 255, 255, 0.3); }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.TopBar_topBar__2Kw0L {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 60px;
  background-color: #1a2c38;
  z-index: 10000;
  opacity: 0.8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden; }
  .TopBar_topBar__2Kw0L.TopBar_hidden__12ZcI {
    top: -60px;
    opacity: 0; }
  .TopBar_topBar__2Kw0L .TopBar_items__3b-OT {
    padding: 10px;
    width: calc(100vw -20px);
    display: flex;
    justify-content: space-between;
    position: relative; }
  .TopBar_topBar__2Kw0L .TopBar_branding__1r6yT {
    position: absolute;
    left: 0;
    right: 0; }
  .TopBar_topBar__2Kw0L .TopBar_back__Guxxk {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100; }
    .TopBar_topBar__2Kw0L .TopBar_back__Guxxk .TopBar_backLabel__2pBw_ {
      padding: 0 6px; }
  .TopBar_topBar__2Kw0L .TopBar_exitButton__37gtw {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-width: 3px;
    stroke-miterlimit: 10; }
  .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg {
    display: flex;
    z-index: 100; }
    .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA {
      cursor: pointer;
      padding: 0 6px;
      width: 50px; }
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_label__3n7nv {
        font-size: 0.8em;
        color: #ffffff; }
        .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_label__3n7nv.TopBar_active__1hbJx {
          color: #4bb6c2; }
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_icon__2Grbm {
        height: 33px;
        margin: auto;
        width: 50%; }
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_shareIcon__18edG {
        fill: none;
        stroke: #fff;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
        stroke-width: 3px; }
        .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_shareIcon__18edG.TopBar_active__1hbJx {
          stroke: #4bb6c2; }
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_cls1__3nueF,
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_cls2__2b_gr,
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_cls5__36mGy {
        fill: none; }
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_cls1__3nueF,
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_cls2__2b_gr,
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_cls3__FzAd2 {
        stroke: #fff; }
        .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_cls1__3nueF.TopBar_active__1hbJx,
        .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_cls2__2b_gr.TopBar_active__1hbJx,
        .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_cls3__FzAd2.TopBar_active__1hbJx {
          stroke: #4bb6c2; }
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_cls1__3nueF,
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_cls2__2b_gr {
        stroke-width: 3px; }
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_cls1__3nueF {
        stroke-miterlimit: 10; }
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_cls2__2b_gr {
        stroke-linecap: round; }
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_cls3__FzAd2 {
        fill: #fff; }
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_cls4__--rXf {
        stroke: none; }
      .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_settingsIcon__2IqZq {
        fill: #fff;
        stroke: #fff; }
        .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_settingsIcon__2IqZq.TopBar_settingsLine__14caZ {
          fill: none;
          stroke: #fff;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 3px; }
        .TopBar_topBar__2Kw0L .TopBar_rightHand__F-ZCg .TopBar_actionItem__2nzEA .TopBar_settingsIcon__2IqZq.TopBar_active__1hbJx {
          fill: #4bb6c2;
          stroke: #4bb6c2; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.DebugButton_debugMode__Whuab {
  width: 33px;
  height: 33px; }
  .DebugButton_debugMode__Whuab:hover .DebugButton_debugIcon__2h3Bo {
    fill: #e8e9e9; }
  .DebugButton_debugMode__Whuab .DebugButton_debugIcon__2h3Bo {
    fill: #ffffff; }
    .DebugButton_debugMode__Whuab .DebugButton_debugIcon__2h3Bo.DebugButton_active__2mC8d {
      fill: #4bb6c2; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.PlayerControls_playControls__oYBLv {
  display: flex;
  align-items: center;
  justify-content: center; }
  .PlayerControls_playControls__oYBLv .PlayerControls_skip__2F79R {
    cursor: pointer; }
    .PlayerControls_playControls__oYBLv .PlayerControls_skip__2F79R:hover .PlayerControls_skipButton__zVbVA {
      fill: #4bb6c2;
      stroke: #4bb6c2; }
    .PlayerControls_playControls__oYBLv .PlayerControls_skip__2F79R .PlayerControls_skipButton__zVbVA {
      fill: #ffffff;
      stroke: #ffffff;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 3px;
      -webkit-transition: 0.25s;
      transition: 0.25s; }
  .PlayerControls_playControls__oYBLv .PlayerControls_playpause__3ZNID {
    padding: 0 16px;
    cursor: pointer; }
    .PlayerControls_playControls__oYBLv .PlayerControls_playpause__3ZNID:hover .PlayerControls_playPauseButton__3qlBL {
      fill: #4bb6c2;
      stroke: #4bb6c2; }
    .PlayerControls_playControls__oYBLv .PlayerControls_playpause__3ZNID .PlayerControls_playPauseButton__3qlBL {
      fill: #ffffff;
      stroke: #ffffff;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 3px;
      -webkit-transition: 0.25s;
      transition: 0.25s; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.VolumeToggle_speakerIcon__h4ZcQ {
  fill: #ffffff; }

.VolumeToggle_nopeBar__1raNX {
  fill: #e94c24; }

.VolumeToggle_mutedIcon__DqnET {
  fill: #939598; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.VolumeControl_volumeControl__2dh2L {
  display: flex; }
  .VolumeControl_volumeControl__2dh2L.VolumeControl_disabled__2NJuW {
    cursor: not-allowed; }
  .VolumeControl_volumeControl__2dh2L .VolumeControl_speaker__373lD {
    padding: 0 16px; }
  .VolumeControl_volumeControl__2dh2L .VolumeControl_sliderWrapper__qHct8 {
    position: relative;
    width: 10vw; }
    .VolumeControl_volumeControl__2dh2L .VolumeControl_sliderWrapper__qHct8 .VolumeControl_slider__1Gndo {
      left: 0;
      right: 0;
      top: 10px;
      position: absolute;
      -webkit-appearance: none;
      width: 100%;
      height: 6px;
      border-radius: 5px;
      background: transparent;
      outline: none;
      opacity: 1; }
      .VolumeControl_volumeControl__2dh2L .VolumeControl_sliderWrapper__qHct8 .VolumeControl_slider__1Gndo.VolumeControl_disabled__2NJuW {
        cursor: not-allowed; }
      .VolumeControl_volumeControl__2dh2L .VolumeControl_sliderWrapper__qHct8 .VolumeControl_slider__1Gndo::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer; }
      .VolumeControl_volumeControl__2dh2L .VolumeControl_sliderWrapper__qHct8 .VolumeControl_slider__1Gndo::-moz-range-thumb {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer; }
    .VolumeControl_volumeControl__2dh2L .VolumeControl_sliderWrapper__qHct8 .VolumeControl_progress__1rNX7 {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      top: 12px;
      height: 6px;
      background-color: #939598; }
      .VolumeControl_volumeControl__2dh2L .VolumeControl_sliderWrapper__qHct8 .VolumeControl_progress__1rNX7.VolumeControl_disabled__2NJuW {
        cursor: not-allowed; }
      .VolumeControl_volumeControl__2dh2L .VolumeControl_sliderWrapper__qHct8 .VolumeControl_progress__1rNX7::-webkit-progress-bar {
        background-color: #939598; }
      .VolumeControl_volumeControl__2dh2L .VolumeControl_sliderWrapper__qHct8 .VolumeControl_progress__1rNX7::-webkit-progress-value {
        background-color: #ffffff; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.FullScreenToggle_fullscreenMode__3WA2E {
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 0 16px; }
  .FullScreenToggle_fullscreenMode__3WA2E:hover .FullScreenToggle_fullScreenIcon__2Y3Jv {
    stroke: #e8e9e9; }
  .FullScreenToggle_fullscreenMode__3WA2E .FullScreenToggle_fullScreenIcon__2Y3Jv {
    fill: none;
    stroke: #ffffff;
    stroke-linecap: round;
    stroke-width: 3px;
    stroke-linejoin: round; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Microphone_microphoneButton__1lzGB {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer; }
  .Microphone_microphoneButton__1lzGB.Microphone_disabled__2-Nrs {
    cursor: not-allowed; }
  .Microphone_microphoneButton__1lzGB .Microphone_microphoneIcon__2I1ck .Microphone_micOn__3NerV {
    fill: #4bb6c2; }
  .Microphone_microphoneButton__1lzGB .Microphone_microphoneIcon__2I1ck .Microphone_micOff__1PgFl {
    fill: #939598; }
  .Microphone_microphoneButton__1lzGB .Microphone_microphoneIcon__2I1ck .Microphone_redLine__2imRf {
    fill: #e94c24; }
  .Microphone_microphoneButton__1lzGB .Microphone_visualizer__2wBuT {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px; }
    .Microphone_microphoneButton__1lzGB .Microphone_visualizer__2wBuT .Microphone_small__3fsrw {
      margin: 0 2px;
      width: 4px;
      height: 2px;
      background-color: #4bb6c2;
      border-radius: 30%; }
      .Microphone_microphoneButton__1lzGB .Microphone_visualizer__2wBuT .Microphone_small__3fsrw.Microphone_one__1R_uF.Microphone_active__1j8ka {
        height: 10px;
        -webkit-animation: Microphone_bouncy__3Ljao 407ms ease alternate infinite;
                animation: Microphone_bouncy__3Ljao 407ms ease alternate infinite; }
      .Microphone_microphoneButton__1lzGB .Microphone_visualizer__2wBuT .Microphone_small__3fsrw.Microphone_two__6hXzD.Microphone_active__1j8ka {
        height: 10px;
        -webkit-animation: Microphone_bouncy__3Ljao 425ms ease alternate infinite;
                animation: Microphone_bouncy__3Ljao 425ms ease alternate infinite; }
      .Microphone_microphoneButton__1lzGB .Microphone_visualizer__2wBuT .Microphone_small__3fsrw.Microphone_three__TI_Qe.Microphone_active__1j8ka {
        height: 10px;
        -webkit-animation: Microphone_bouncy__3Ljao 472ms ease alternate infinite;
                animation: Microphone_bouncy__3Ljao 472ms ease alternate infinite; }
      .Microphone_microphoneButton__1lzGB .Microphone_visualizer__2wBuT .Microphone_small__3fsrw.Microphone_four__2qs71.Microphone_active__1j8ka {
        height: 10px;
        -webkit-animation: Microphone_bouncy__3Ljao 499ms ease alternate infinite;
                animation: Microphone_bouncy__3Ljao 499ms ease alternate infinite; }
    .Microphone_microphoneButton__1lzGB .Microphone_visualizer__2wBuT .Microphone_large__3wCnE {
      margin: 0 2px;
      width: 6px;
      height: 2px;
      background-color: #4bb6c2;
      border-radius: 30%;
      -webkit-transition: height 1s ease-in-out;
      transition: height 1s ease-in-out; }
      .Microphone_microphoneButton__1lzGB .Microphone_visualizer__2wBuT .Microphone_large__3wCnE.Microphone_active__1j8ka {
        height: 15px; }

@-webkit-keyframes Microphone_bouncy__3Ljao {
  0% {
    height: 1px; }
  50% {
    height: 10px; }
  100% {
    height: 1px; } }

@keyframes Microphone_bouncy__3Ljao {
  0% {
    height: 1px; }
  50% {
    height: 10px; }
  100% {
    height: 1px; } }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.BottomBar_bottomBar__1SWiz {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 60px;
  background-color: #1a2c38;
  z-index: 10000;
  opacity: 0.8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden;
  padding-top: 6px; }
  .BottomBar_bottomBar__1SWiz.BottomBar_hidden__4vpRl {
    bottom: -60px;
    opacity: 0; }
  .BottomBar_bottomBar__1SWiz .BottomBar_centerOptions__1haCF {
    position: absolute;
    left: 50%;
    right: 50%;
    width: 60px;
    margin: auto;
    margin-left: -30px; }
  .BottomBar_bottomBar__1SWiz .BottomBar_items__4rgHU {
    padding: 10px;
    width: calc(100vw -20px);
    display: flex;
    justify-content: space-between; }
  .BottomBar_bottomBar__1SWiz .BottomBar_back____bLe {
    cursor: pointer; }
  .BottomBar_bottomBar__1SWiz .BottomBar_rightOptions__2ZwMp {
    display: flex; }
  .BottomBar_bottomBar__1SWiz .BottomBar_exitButton__1rmoM {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-width: 3px; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
/* form starting stylings ------------------------------- */
.Input_group__GycIA {
  position: relative;
  margin-bottom: 5px;
  width: 100%;
  margin-right: 10px;
  margin-top: 26px; }
  .Input_group__GycIA.Input_size--small__3X6Pz {
    margin-bottom: 0; }

.Input_customInput__22UPD {
  font-size: 1em;
  padding: 10px 3px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-ne-grey);
  /* active state */ }
  .Input_customInput__22UPD:disabled {
    cursor: not-allowed;
    background-color: var(--color-ne-grey);
    color: var(--color-ne-grey); }
  .Input_customInput__22UPD:focus {
    outline: none; }
  .Input_customInput__22UPD:focus ~ .Input_bar__3uYJl:before,
  .Input_customInput__22UPD:focus ~ .Input_bar__3uYJl:after {
    width: 50%; }

.Input_customInput__22UPD:focus ~ .Input_highlight__c-xm3 {
  -webkit-animation: Input_inputHighlighter__2F7u_ 0.3s ease;
          animation: Input_inputHighlighter__2F7u_ 0.3s ease; }

.Input_customInput__22UPD:-webkit-autofill,
.Input_customInput__22UPD:-webkit-autofill:hover,
.Input_customInput__22UPD:-webkit-autofill:focus {
  background-color: var(--color-white) !important; }

.Input_customInput__22UPD:valid ~ label {
  top: -20px;
  left: 0;
  font-size: 14px; }

.Input_customInput__22UPD:focus ~ label {
  top: -20px;
  left: 0;
  font-size: 14px;
  color: var(--color-ne-green); }

.Input_size--small__3X6Pz > .Input_customInput__22UPD {
  font-size: 0.8em;
  padding: 4px 0;
  margin: 0;
  border: none; }

.Input_theme--light__3rd35 > .Input_customInput__22UPD {
  background-color: var(--color-white); }

.Input_theme--dark__3gHtd > .Input_customInput__22UPD {
  background-color: var(--color-offblack);
  color: var(--color-offwhite); }

/* LABEL ======================================= */
label {
  color: var(--color-gray-medium);
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all; }

/* BOTTOM BARS ================================= */
.Input_bar__3uYJl {
  position: relative;
  display: block;
  width: 100%; }

.Input_bar__3uYJl:before,
.Input_bar__3uYJl:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: var(--color-ne-green);
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all; }

.Input_bar__3uYJl:before {
  left: 50%; }

.Input_bar__3uYJl:after {
  right: 50%; }

/* ICON ================================== */
.Input_icon__YGkpt {
  position: absolute;
  top: 1px;
  right: 6px;
  pointer-events: none; }

/* HIGHLIGHTER ================================== */
.Input_highlight__c-xm3 {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5; }

/* ANIMATIONS ================ */
@-webkit-keyframes Input_inputHighlighter__2F7u_ {
  from {
    background: var(--color-ne-green); }
  to {
    width: 0;
    background: transparent; } }
@keyframes Input_inputHighlighter__2F7u_ {
  from {
    background: var(--color-ne-green); }
  to {
    width: 0;
    background: transparent; } }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Modal_modalContainer__28A7L {
  position: fixed;
  z-index: 1000;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1a2c38), color-stop(25%, rgba(37, 61, 77, 0.5)), color-stop(40%, rgba(37, 61, 77, 0.2)), color-stop(60%, rgba(37, 61, 77, 0.2)), color-stop(75%, rgba(37, 61, 77, 0.5)), to(#1a2c38));
  background-image: -webkit-linear-gradient(top, #1a2c38 0%, rgba(37, 61, 77, 0.5) 25%, rgba(37, 61, 77, 0.2) 40%, rgba(37, 61, 77, 0.2) 60%, rgba(37, 61, 77, 0.5) 75%, #1a2c38 100%);
  background-image: linear-gradient(to bottom, #1a2c38 0%, rgba(37, 61, 77, 0.5) 25%, rgba(37, 61, 77, 0.2) 40%, rgba(37, 61, 77, 0.2) 60%, rgba(37, 61, 77, 0.5) 75%, #1a2c38 100%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(10px) grayscale(100%);
          backdrop-filter: blur(10px) grayscale(100%); }

.Modal_modalBox__3YltP {
  width: 750px;
  height: 580px;
  opacity: 0.9;
  border-radius: 20px;
  background-color: #1a2c38; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Button_button__2Lf63 {
  border: none;
  cursor: pointer;
  border-radius: 8px; }
  .Button_button__2Lf63:disabled {
    background-color: #b0b4b8;
    cursor: not-allowed; }

.Button_size--tiny__3KLKG {
  padding: 2px;
  font-size: 0.8em; }

.Button_size--small__2eilW {
  padding: 6px;
  font-size: 0.8em; }

.Button_size--normal__3luMW {
  padding: 10px 16px;
  font-size: 1em; }

.Button_size--large__1_H7l {
  padding: 16px 18px;
  font-size: 1.2em; }

.Button_theme--trackButton__3tZhm {
  color: #fff;
  text-align: left; }
  .Button_theme--trackButton__3tZhm:hover {
    opacity: 0.8; }

.Button_theme--success__27Wx_ {
  background-color: #acd145;
  color: #fff; }
  .Button_theme--success__27Wx_:focus, .Button_theme--success__27Wx_:hover:enabled {
    background-color: #92b62d;
    color: #fff; }
  .Button_theme--success__27Wx_.Button_inverse__2JGsR {
    color: #acd145;
    fill: #acd145;
    background-color: transparent; }
    .Button_theme--success__27Wx_.Button_inverse__2JGsR:focus, .Button_theme--success__27Wx_.Button_inverse__2JGsR:hover {
      color: #92b62d;
      background-color: rgba(172, 209, 69, 0.5); }

.Button_theme--primary__1jK-E {
  min-width: 200px;
  background-color: #ffffff;
  opacity: 0.75;
  font-family: niveau-grotesk, sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #1a2c38;
  border: solid 3px #ffffff; }
  .Button_theme--primary__1jK-E:focus, .Button_theme--primary__1jK-E:hover:enabled {
    opacity: 1; }
  .Button_theme--primary__1jK-E.Button_inverse__2JGsR {
    opacity: 1;
    color: #ffffff;
    border: solid 3px #ffffff;
    background-color: transparent; }
    .Button_theme--primary__1jK-E.Button_inverse__2JGsR:focus, .Button_theme--primary__1jK-E.Button_inverse__2JGsR:hover {
      color: #1a2c38;
      background-color: #ffffff; }
    .Button_theme--primary__1jK-E.Button_inverse__2JGsR.Button_raised__5g9Uw {
      color: #1a2c38;
      background-color: #ffffff; }

.Button_theme--danger__267ha {
  background-color: #e94c24;
  color: #fff; }
  .Button_theme--danger__267ha:focus, .Button_theme--danger__267ha:hover:enabled {
    background-color: #c63814;
    color: #fff; }
  .Button_theme--danger__267ha.Button_inverse__2JGsR {
    color: #bf360c;
    background-color: transparent; }
    .Button_theme--danger__267ha.Button_inverse__2JGsR:focus, .Button_theme--danger__267ha.Button_inverse__2JGsR:hover {
      color: #c63814;
      background-color: rgba(233, 76, 36, 0.5); }

.Button_theme--warning__2yAsS {
  background-color: #f1873b;
  color: #fff; }
  .Button_theme--warning__2yAsS:focus, .Button_theme--warning__2yAsS:hover:enabled {
    background-color: #e86b11;
    color: #fff; }
  .Button_theme--warning__2yAsS.Button_inverse__2JGsR {
    color: #bf360c;
    background-color: transparent; }
    .Button_theme--warning__2yAsS.Button_inverse__2JGsR:focus, .Button_theme--warning__2yAsS.Button_inverse__2JGsR:hover {
      color: #bc5500;
      background-color: rgba(239, 108, 0, 0.5); }

.Button_theme--light__25z32 {
  background-color: #fff;
  color: #222; }
  .Button_theme--light__25z32:focus, .Button_theme--light__25z32:hover {
    background-color: #e6e6e6;
    color: #222; }
  .Button_theme--light__25z32.Button_inverse__2JGsR {
    color: #fff;
    background-color: transparent; }
    .Button_theme--light__25z32.Button_inverse__2JGsR:focus, .Button_theme--light__25z32.Button_inverse__2JGsR:hover {
      color: #222;
      background-color: rgba(255, 255, 255, 0.5); }

.Button_theme--dark__1Suaf {
  background-color: #222;
  color: #fff; }
  .Button_theme--dark__1Suaf:focus, .Button_theme--dark__1Suaf:hover {
    background-color: #090909;
    color: #fff; }
  .Button_theme--dark__1Suaf.Button_inverse__2JGsR {
    color: #222;
    background-color: transparent; }
    .Button_theme--dark__1Suaf.Button_inverse__2JGsR:focus, .Button_theme--dark__1Suaf.Button_inverse__2JGsR:hover {
      color: #fff;
      background-color: rgba(34, 34, 34, 0.5); }

.Button_link__Kr8EU {
  background-color: transparent;
  color: #4bb6c2; }
  .Button_link__Kr8EU:focus, .Button_link__Kr8EU:hover:enabled {
    background-color: #f8f9fb;
    color: #3798a3; }

.Button_wide__1IPLq {
  width: 100%; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
/* Split Button */
.SplitButton_buttonGroup__KVgne {
  position: relative;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  display: inline-flex;
  vertical-align: middle; }
  .SplitButton_buttonGroup__KVgne.SplitButton_button__3GKFE:first-child {
    margin-left: 0; }

.SplitButton_dropdownMenu__ohetq {
  position: absolute;
  top: calc(100% - 3px);
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.12rem 0 0;
  font-size: 1rem;
  color: #222;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }
  .SplitButton_dropdownMenu__ohetq.SplitButton_active__3Z5cp {
    display: block; }

.SplitButton_dropdownItem__1fAvv {
  display: block;
  padding: 3px 1.5rem;
  clear: both;
  font-weight: 400;
  color: #222;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
  cursor: pointer;
  background-color: #fff; }
  .SplitButton_dropdownItem__1fAvv:hover {
    background-color: shade(#fff, 10%); }
  .SplitButton_dropdownItema__22H-l {
    touch-action: manipulation;
    text-decoration: none; }

.SplitButton_dropdownDivider__2jHxD {
  height: 1px;
  margin: 0.5rem 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.2); }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.DropButton_buttonGroup__1ETm6 {
  position: relative;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  display: inline-flex;
  vertical-align: middle; }
  .DropButton_buttonGroup__1ETm6.DropButton_button__3CYFQ:first-child {
    margin-left: 0; }
  .DropButton_buttonGroup__1ETm6.DropButton_wide__3Eo_Q {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0; }

.DropButton_dropdownMenu__1ZhOB {
  position: absolute;
  top: calc(100% - 3px);
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.12rem 0 0;
  font-size: 1rem;
  color: #222;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }
  .DropButton_dropdownMenu__1ZhOB.DropButton_active__3pb-_ {
    display: block; }

.DropButton_dropdownItem__13EHD {
  display: block;
  padding: 3px 1.5rem;
  clear: both;
  font-weight: 400;
  color: #222;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
  cursor: pointer;
  background-color: #fff; }
  .DropButton_dropdownItem__13EHD:hover {
    background-color: shade(#fff, 10%); }
  .DropButton_dropdownItema__2fMQ9 {
    touch-action: manipulation;
    text-decoration: none; }

.StartButton_buttonContainer__b5Yuz {
  cursor: pointer; }

.StartButton_startButton__qgat0 .StartButton_clsOne__2MGWR {
  fill: rgba(26, 44, 56, 0.5);
  stroke: rgba(112, 112, 112, 0.5);
  -webkit-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer; }

.StartButton_startButton__qgat0 .StartButton_clsTwo__2LKRV {
  fill: rgba(255, 255, 255, 0.8);
  stroke: rgba(255, 255, 255, 0.8);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-width: 3px;
  -webkit-transition: 0.2s;
  transition: 0.2s; }

.StartButton_startButton__qgat0 .StartButton_clsThree__2OVZO {
  stroke: none; }

.StartButton_startButton__qgat0 .StartButton_clsFour__1lyc2 {
  fill: none; }

.StartButton_startButton__qgat0:hover .StartButton_clsOne__2MGWR {
  fill: rgba(26, 44, 56, 0.8);
  stroke: rgba(112, 112, 112, 0.8); }

.StartButton_startButton__qgat0:hover .StartButton_clsTwo__2LKRV {
  fill: white;
  stroke: rgba(255, 255, 255, 0.8); }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Drawer_drawer__XaZMH {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30vw;
  opacity: 0.9;
  background-color: #1a2c38;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden;
  z-index: 9000; }
  .Drawer_drawer__XaZMH.Drawer_active__26UDn {
    width: 0;
    opacity: 0; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
/* ==========  Select Field Variables ========== */
:root {
  --color-black: #000;
  --select-background-color: transparent;
  --select-border-color: rgba(0, 0, 0, 0.12);
  --select-active-border-color: rgba(0, 162, 255, 1);
  --select-hover-border-color: rgba(0, 162, 255, 0.8);
  --select-font-size: 14px;
  --select-color: rgba(0, 0, 0, 0.26);
  --select-padding: 4px; }

/* ==========  Select Field ========== */
.Select_mdlSelectfield__12uDp {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  position: relative;
  /* Style Select Field */ }
  .Select_mdlSelectfield__12uDp label {
    display: none; }
  .Select_mdlSelectfield__12uDp .Select_nfxSelect__Xu8oN {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
  .Select_mdlSelectfield__12uDp:after {
    position: absolute;
    top: 0.75em;
    right: 0.5em;
    /* Styling the down arrow */
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: 0.25em solid transparent;
    border-right: 0.25em solid transparent;
    border-top: 0.375em solid transparent;
    border-top: 0.375em solid var(--select-background-color);
    pointer-events: none; }
  .Select_mdlSelectfield__12uDp .Select_nfxSelect__Xu8oN {
    font-family: inherit;
    background-color: rgba(255, 255, 255, 0.6);
    width: 100%;
    padding: 4px 0;
    padding: var(--select-padding) 0;
    font-size: 14px;
    font-size: var(--select-font-size);
    color: #000;
    color: var(--color-black);
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid var(--select-border-color); }
    .Select_mdlSelectfield__12uDp .Select_nfxSelect__Xu8oN:hover {
      border-bottom: 1px solid rgba(0, 162, 255, 0.8);
      border-bottom: 1px solid var(--select-hover-border-color); }
    .Select_mdlSelectfield__12uDp .Select_nfxSelect__Xu8oN:focus {
      outline: none;
      border-bottom: 1px solid rgba(0, 162, 255, 1);
      border-bottom: 1px solid var(--select-active-border-color); }
  .Select_mdlSelectfield__12uDp .Select_size--small__180dH .Select_nfxSelect__Xu8oN {
    padding: 0;
    font-size: 0.8em; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Settings_settingsDrawer__38gj6 {
  margin-top: 100px;
  color: #ffffff;
  text-align: left;
  width: calc(25vw - 60px);
  padding: 0 30px; }
  .Settings_settingsDrawer__38gj6 h3 {
    margin-top: 26px;
    margin-bottom: 2px; }
  .Settings_settingsDrawer__38gj6 a {
    color: #f1873b; }
  .Settings_settingsDrawer__38gj6 .Settings_qrcodeWrap__2Ctqk {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px; }
  .Settings_settingsDrawer__38gj6 .Settings_remoteUrl__144G_ {
    word-wrap: break-word; }
  .Settings_settingsDrawer__38gj6 .Settings_playText__3hKcD {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 12px 0;
    cursor: pointer; }
    .Settings_settingsDrawer__38gj6 .Settings_playText__3hKcD .Settings_playIconContainer__2okkN {
      padding-right: 6px; }
    .Settings_settingsDrawer__38gj6 .Settings_playText__3hKcD .Settings_playIcon__2hCbI {
      fill: none;
      stroke: #f1873b;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 3px; }
      .Settings_settingsDrawer__38gj6 .Settings_playText__3hKcD .Settings_playIcon__2hCbI.Settings_active__2XaQQ {
        fill: #f1873b;
        -webkit-animation-name: Settings_pulsate__1-wlw;
                animation-name: Settings_pulsate__1-wlw;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-duration: 1.5s;
                animation-duration: 1.5s; }
    .Settings_settingsDrawer__38gj6 .Settings_playText__3hKcD .Settings_playLabel__13rSI {
      color: #f1873b; }
      .Settings_settingsDrawer__38gj6 .Settings_playText__3hKcD .Settings_playLabel__13rSI.Settings_active__2XaQQ {
        color: #ffffff;
        -webkit-animation-name: Settings_pulsate__1-wlw;
                animation-name: Settings_pulsate__1-wlw;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-duration: 1.5s;
                animation-duration: 1.5s; }

@-webkit-keyframes Settings_pulsate__1-wlw {
  0% {
    color: #ffffff;
    stroke: #ffffff; }
  50% {
    color: #f1873b;
    stroke: #f1873b; }
  100% {
    color: #ffffff;
    stroke: #ffffff; } }

@keyframes Settings_pulsate__1-wlw {
  0% {
    color: #ffffff;
    stroke: #ffffff; }
  50% {
    color: #f1873b;
    stroke: #f1873b; }
  100% {
    color: #ffffff;
    stroke: #ffffff; } }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.About_aboutDrawer__19Jyl {
  margin-top: 100px;
  color: #ffffff;
  text-align: left;
  width: calc(25vw - 60px);
  padding: 0 30px; }
  .About_aboutDrawer__19Jyl h3 {
    margin-top: 26px;
    margin-bottom: 2px; }
  .About_aboutDrawer__19Jyl a {
    color: #f1873b; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Share_shareDrawer__3GJco {
  margin-top: 100px;
  color: #ffffff;
  text-align: left;
  width: calc(25vw - 60px);
  padding: 0 30px; }
  .Share_shareDrawer__3GJco h3 {
    margin-top: 26px;
    margin-bottom: 2px; }
  .Share_shareDrawer__3GJco a {
    color: #4bb6c2;
    text-decoration: none; }
  .Share_shareDrawer__3GJco .Share_shareItem__2IPil {
    padding: 10px 0; }


/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Soundscape_soundscape__2df7z {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  padding: 5vw;
  margin: 0vw;
  background-color: #1a2c38;
  font-size: 2vw; }
  .Soundscape_soundscape__2df7z h1 {
    margin: 0;
    margin-bottom: 1em; }
  .Soundscape_soundscape__2df7z img {
    width: 50%; }
  .Soundscape_soundscape__2df7z .Soundscape_authors__nB4bk {
    margin: 1em 0; }
  .Soundscape_soundscape__2df7z .Soundscape_description__Z435R {
    margin: 1em 0; }
  .Soundscape_soundscape__2df7z .Soundscape_copyright__b357B {
    font-size: 0.7em;
    margin: 1em 0; }
  .Soundscape_soundscape__2df7z a {
    color: #4bb6c2;
    text-decoration: none; }

.CollectionItem_contentItem__3e5e2 {
  width: 17vw;
  min-width: 160px;
  min-height: 200px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 2px; }
  .CollectionItem_contentItem__3e5e2:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer; }
  .CollectionItem_contentItem__3e5e2.CollectionItem_selected__2rDx- {
    background-color: rgba(255, 255, 255, 0.2); }

.CollectionItem_imageContainer__3EbPf {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15vw;
  min-width: 150px;
  height: 15vw;
  min-height: 150px;
  overflow: hidden; }

.CollectionItem_mediaImage__1aE4M {
  width: 100%;
  height: auto; }

.CollectionItem_title__2zoH0 {
  line-height: 2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.CollectionItem_loadingWrap__2TxJx {
  text-align: center;
  width: 17vw;
  min-width: 160px;
  min-height: 200px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.CircularProgressbar-path {
  stroke: red; }

.CircularProgressbar-trail {
  stroke: gray; }

.CircularProgressbar-text {
  fill: yellow; }

.CircularProgressbar-background {
  fill: green; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.EndEvent_branding__3teJd {
  position: fixed;
  top: 20px;
  left: 20px; }
  .EndEvent_branding__3teJd .EndEvent_subtext__3Vlyw {
    color: #ffffff;
    font-size: 1.3em; }

.EndEvent_endEvent__1bSFp {
  position: fixed;
  font-size: 3em;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  display: none;
  opacity: 1;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
  z-index: 185;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .EndEvent_endEvent__1bSFp .EndEvent_logo__3iF_Z {
    padding: 0;
    margin-bottom: 30px; }
  .EndEvent_endEvent__1bSFp .EndEvent_thanksText__1JyUX {
    color: #ffffff;
    font-size: 0.8em;
    margin-bottom: 30px; }
  .EndEvent_endEvent__1bSFp .EndEvent_completedButton__1plJ7 {
    margin: 10px;
    border: 2px solid #ffffff;
    background: #1a2c38;
    border-radius: 10px;
    padding: 10px 40px;
    -webkit-transition: 0.25s;
    transition: 0.25s;
    cursor: pointer; }
    .EndEvent_endEvent__1bSFp .EndEvent_completedButton__1plJ7.EndEvent_active__2yl0z {
      border: 2px solid #ffffff;
      background: #ffffff;
      color: #1a2c38; }
    .EndEvent_endEvent__1bSFp .EndEvent_completedButton__1plJ7:hover {
      border: 2px solid #ffffff;
      background: #ffffff;
      color: #1a2c38; }
  .EndEvent_endEvent__1bSFp .EndEvent_countdown__DDiPy {
    width: 50px;
    height: 50px; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Rating_rating__VdE1n .Rating_questionText__2fRRB {
  color: #ffffff;
  font-size: 0.8em;
  margin-bottom: 30px; }

.Rating_rating__VdE1n .Rating_buttonContainer__wPd3z {
  display: flex;
  align-items: center;
  justify-content: center; }
  .Rating_rating__VdE1n .Rating_buttonContainer__wPd3z .Rating_completedButton__3cd1d {
    margin: 10px;
    border: 2px solid #ffffff;
    background: #1a2c38;
    border-radius: 10px;
    padding: 10px 40px;
    -webkit-transition: 0.25s;
    transition: 0.25s;
    cursor: pointer; }
    .Rating_rating__VdE1n .Rating_buttonContainer__wPd3z .Rating_completedButton__3cd1d.Rating_active__2gIAu {
      border: 2px solid #ffffff;
      background: #ffffff;
      color: #1a2c38; }
    .Rating_rating__VdE1n .Rating_buttonContainer__wPd3z .Rating_completedButton__3cd1d:hover {
      border: 2px solid #ffffff;
      background: #ffffff;
      color: #1a2c38; }

.Rating_rating__VdE1n .Rating_thumbContainter__14atB {
  margin: 15px; }
  .Rating_rating__VdE1n .Rating_thumbContainter__14atB.Rating_hidden__1vTzI {
    display: none; }

.Rating_rating__VdE1n .Rating_thumb__1GDtH {
  fill: none;
  stroke: #ffffff;
  stroke-miterlimit: 10;
  stroke-width: 3px; }
  .Rating_rating__VdE1n .Rating_thumb__1GDtH.Rating_active__2gIAu {
    fill: #ffffff; }
  .Rating_rating__VdE1n .Rating_thumb__1GDtH.Rating_selected__16J5D {
    fill: #4bb6c2;
    stroke: none; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Branding_branding__6zLCX {
  position: fixed;
  top: 20px;
  left: 20px; }
  .Branding_branding__6zLCX .Branding_logo__1uIqP {
    width: 10vw;
    height: auto;
    padding: 0; }
  .Branding_branding__6zLCX .Branding_subtext__3AgCQ {
    color: #ffffff;
    font-size: 1.3em; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.CarouselItem_contentItem__2yJW6 {
  opacity: 0.75;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  display: none; }
  .CarouselItem_contentItem__2yJW6 .CarouselItem_imageContainer__1Rw_U {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20vw;
    min-width: 150px;
    height: auto;
    overflow: hidden;
    -webkit-transition: all 500ms ease-out;
    transition: all 500ms ease-out; }
    .CarouselItem_contentItem__2yJW6 .CarouselItem_imageContainer__1Rw_U img {
      display: block; }
  .CarouselItem_contentItem__2yJW6 .CarouselItem_title__2Uqiw {
    font-size: 2em;
    line-height: 2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none; }
  .CarouselItem_contentItem__2yJW6 .CarouselItem_description__36aj0 {
    font-size: 1.4em;
    display: none; }
  .CarouselItem_contentItem__2yJW6.CarouselItem_selected__2_koI {
    z-index: 100;
    opacity: 1;
    order: 2;
    display: block;
    z-index: 3;
    -webkit-transform: scale(2.5);
            transform: scale(2.5); }
    .CarouselItem_contentItem__2yJW6.CarouselItem_selected__2_koI .CarouselItem_imageContainer__1Rw_U {
      box-shadow: 0 0 5px 1px #4bb6c2, 0 0 5px 1px #4bb6c2, 0 0 5px 1px #4bb6c2;
      /* outer cyan */ }
    .CarouselItem_contentItem__2yJW6.CarouselItem_selected__2_koI .CarouselItem_title__2Uqiw {
      display: block; }
    .CarouselItem_contentItem__2yJW6.CarouselItem_selected__2_koI .CarouselItem_description__36aj0 {
      display: block; }
  .CarouselItem_contentItem__2yJW6.CarouselItem_left__1cUY_ {
    order: 1;
    display: block;
    -webkit-transform: translateX(-280px) scale(1.5);
            transform: translateX(-280px) scale(1.5);
    z-index: 1; }
  .CarouselItem_contentItem__2yJW6.CarouselItem_right__1gE-I {
    order: 3;
    display: block;
    -webkit-transform: translateX(280px) scale(1.5);
            transform: translateX(280px) scale(1.5);
    z-index: 2; }

.CarouselItem_mediaImage__1NYUT {
  width: 100%;
  height: auto; }

.CarouselItem_loadingWrap__1Xo26 {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.CarouselDetails_backgroundImage__3yD0Y {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 0; }

.CarouselDetails_detailsText__CDzpv {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 25vh;
  color: #ffffff;
  font-size: 1.4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .CarouselDetails_detailsText__CDzpv .CarouselDetails_title__3q9I0 {
    font-weight: 600;
    line-height: 2;
    color: #ffffff;
    font-size: 2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .CarouselDetails_detailsText__CDzpv .CarouselDetails_credits__1oVqQ {
    font-weight: 600;
    line-height: 0.92;
    color: #ffffff;
    font-size: 0.8em; }
  .CarouselDetails_detailsText__CDzpv .CarouselDetails_description__isHCm {
    color: #ffffff;
    font-size: 1.4em;
    width: 60vw; }
  @media screen and (max-width: 1680px) {
    .CarouselDetails_detailsText__CDzpv .CarouselDetails_titleAdditional__1rUYe {
      font-size: 1.2em; }
    .CarouselDetails_detailsText__CDzpv .CarouselDetails_descriptionAdditional__1FuAu {
      font-size: 1.0em; } }
  @media screen and (max-width: 960px) {
    .CarouselDetails_detailsText__CDzpv .CarouselDetails_titleAdditional__1rUYe {
      font-size: 1em; }
    .CarouselDetails_detailsText__CDzpv .CarouselDetails_descriptionAdditional__1FuAu {
      font-size: 0.4em; } }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Carousel_Content__1_EUp {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; }

.Carousel_ContentNotReady__33e28 {
  display: none; }

.Carousel_resourceList__Fx2kA {
  width: 100vw;
  padding: 0px;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  color: #ffffff;
  flex-flow: row nowrap; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Pairing_App__pAq1b {
  text-align: center; }

.Pairing_Pairing__1VpFI {
  background-color: #939598;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.Pairing_NFXlogoContainer__155Qw {
  background-color: #1a2c38;
  border-radius: 2em;
  padding: 50px;
  margin: 10px;
  text-align: center; }

.Pairing_loadingContainer__3YOEi {
  background-color: #1a2c38;
  border-radius: 2em;
  padding: 50px;
  margin: 10px;
  text-align: center; }

.Pairing_NFXlogo__1XWNx {
  /* animation: App-logo-spin infinite 50s linear; */
  width: 50vw; }

.Pairing_helpText__2wfZ4 {
  margin: 16px;
  font-size: 2.5vw;
  color: #f0f0f0; }

@-webkit-keyframes Pairing_App-logo-spin__f3Gdn {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes Pairing_App-logo-spin__f3Gdn {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.MobilePair_Pairing__2eCG0 {
  background-color: #1a2c38;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  color: #ffffff; }
  .MobilePair_Pairing__2eCG0 .MobilePair_NFXlogoContainer__13d3F {
    padding: 50px;
    margin: 10px;
    text-align: center; }
    .MobilePair_Pairing__2eCG0 .MobilePair_NFXlogoContainer__13d3F .MobilePair_NFXlogo__m-dAw {
      /* animation: App-logo-spin infinite 50s linear; */
      width: 50vw; }
  .MobilePair_Pairing__2eCG0 .MobilePair_helpText__2X-1f {
    margin: 16px;
    font-size: 2.5vw; }
  .MobilePair_Pairing__2eCG0 .MobilePair_inputContainer__10KMw {
    margin-top: 20vh;
    font-size: 2.5vw; }
    .MobilePair_Pairing__2eCG0 .MobilePair_inputContainer__10KMw .MobilePair_inputBox__2nBNt {
      width: 50vw; }
      .MobilePair_Pairing__2eCG0 .MobilePair_inputContainer__10KMw .MobilePair_inputBox__2nBNt input {
        font-size: 1.2em;
        color: #ffffff;
        text-align: center;
        background-color: #1a2c38;
        border: 1px solid #ffffff;
        border-radius: 2px; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.auth_loginPage__tX6fp {
  color: var(--color-ne-blue);
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.auth_loginbox__2sb2G {
  background-color: white;
  padding: 10px 30px;
  border-radius: 10px; }

.auth_facebookButton__3Hbdi {
  padding: 10px 0; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Content_Content__3al6l {
  background-color: #1a2c38;
  min-height: calc(100vh + 120px);
  padding: 20px;
  margin: 0; }
  .Content_Content__3al6l h3 {
    color: #ffffff; }

.Content_resourceList__28gOB {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  color: #ffffff;
  flex-flow: row wrap;
  overflow: auto; }

.Content_contentItem__1vl2X {
  width: 17vw;
  min-width: 160px;
  min-height: 200px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 2px; }
  .Content_contentItem__1vl2X:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer; }
  .Content_contentItem__1vl2X.Content_selected__1USwC {
    background-color: rgba(255, 255, 255, 0.2); }

.Content_imageContainer__3JIrZ {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15vw;
  min-width: 150px;
  height: 15vw;
  min-height: 150px;
  overflow: hidden; }

.Content_mediaImage__2HGos {
  width: 100%;
  height: auto; }

.Content_title__Ur73P {
  line-height: 2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.Content_loadingWrap__26y0F {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.CollectionItem_Content__1W2z1 {
  background-color: #1a2c38;
  min-height: calc(100vh + 120px);
  padding: 20px;
  margin: 0; }

.CollectionItem_resourceList__3ugOS {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  color: #ffffff;
  flex-flow: row nowrap;
  overflow: auto; }

.CollectionItem_contentItem__M8s8r {
  width: 36vw;
  min-height: 200px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 20px;
  -webkit-transition: 500ms;
  transition: 500ms;
  border-radius: 5px;
  opacity: 0.7; }
  .CollectionItem_contentItem__M8s8r:hover {
    background-color: rgba(75, 182, 194, 0.8);
    cursor: pointer;
    opacity: 1; }
  .CollectionItem_contentItem__M8s8r.CollectionItem_selected__2QWpW {
    background-color: rgba(75, 182, 194, 0.8);
    opacity: 1; }

.CollectionItem_imageContainer__3AqyF {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 35vw;
  min-width: 150px;
  height: auto;
  min-height: 150px;
  overflow: hidden; }

.CollectionItem_mediaImage__2P2fA {
  width: 100%;
  height: auto; }

.CollectionItem_title__2q0yS {
  line-height: 2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.CollectionItem_loadingWrap__3tfL- {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Collection_Content__3Vs4B {
  background-color: #1a2c38;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center; }

.Collection_branding__2y5Ns {
  position: fixed;
  top: 0;
  left: 0; }
  .Collection_branding__2y5Ns .Collection_logo__u109N {
    width: 10vw;
    height: auto;
    padding: 2vh; }

.Collection_resourceList__1zq-G {
  padding: 0px;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  color: #ffffff;
  flex-flow: row nowrap;
  overflow: hidden; }

.Collection_contentItem__2_b1h {
  width: 17vw;
  min-width: 160px;
  min-height: 200px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 20px;
  -webkit-transition: 500ms;
  transition: 500ms; }
  .Collection_contentItem__2_b1h:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer; }
  .Collection_contentItem__2_b1h.Collection_selected__beMYv {
    background-color: rgba(255, 255, 255, 0.2); }

.Collection_imageContainer__11srn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15vw;
  min-width: 150px;
  height: 15vw;
  min-height: 150px;
  overflow: hidden; }

.Collection_mediaImage__19W8G {
  width: 100%;
  height: auto; }

.Collection_title__MPOf- {
  line-height: 2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.Collection_loadingWrap__3XM-Z {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.StbCollection_imageBackground__2IAlu {
  position: fixed;
  -webkit-filter: blur(50px);
          filter: blur(50px);
  background-color: #1a2c38;
  height: 100vh;
  width: 100vw;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.3; }

.StbCollection_Content__RbSuS {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center; }

.StbCollection_helpText__2pyYo {
  position: fixed;
  top: 10vh;
  left: 0;
  right: 0;
  height: 10vh;
  color: #ffffff;
  font-size: 2em;
  font-style: italic; }

@media screen and (max-width: 1680px) {
  .StbCollection_helpTextAdditional__3JqoB {
    top: 15vh;
    font-size: 1.8em; } }

@media screen and (max-width: 960px) {
  .StbCollection_helpTextAdditional__3JqoB {
    top: 15vh;
    font-size: 1em; } }

.StbCollection_resourceList__3DbPo {
  padding: 0px;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  color: #ffffff;
  flex-flow: row nowrap;
  overflow: hidden; }

.StbCollection_contentItem__2H_i- {
  width: 17vw;
  min-width: 160px;
  min-height: 200px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 20px;
  -webkit-transition: 500ms;
  transition: 500ms; }
  .StbCollection_contentItem__2H_i-:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer; }
  .StbCollection_contentItem__2H_i-.StbCollection_selected__1Ykxp {
    background-color: rgba(255, 255, 255, 0.2); }

.StbCollection_imageContainer__2Zswj {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15vw;
  min-width: 150px;
  height: 15vw;
  min-height: 150px;
  overflow: hidden; }

.StbCollection_mediaImage__3Ejmf {
  width: 100%;
  height: auto; }

.StbCollection_title__3jREx {
  line-height: 2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.StbCollection_loadingWrap__2AVt4 {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.StbCollection_splashVideo__2jBm6 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: auto; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Wait_waitMode__-uG6_ {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  font-size: 3em; }
  .Wait_waitMode__-uG6_ div {
    margin: 0 10vw; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Voice_voice__1p2J9 {
  text-align: center;
  color: #ffffff;
  font-size: 5vw;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center; }
  .Voice_voice__1p2J9 .Voice_loading__2uktY {
    margin: 10vh; }
  .Voice_voice__1p2J9 .Voice_listeningMode__2n1JZ {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .Voice_voice__1p2J9 .Voice_listeningMode__2n1JZ .Voice_NFXlogo__3vXHa {
      width: 30vw;
      height: auto; }
    .Voice_voice__1p2J9 .Voice_listeningMode__2n1JZ .Voice_microphone__myaHw {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ffffff;
      border-radius: 100%;
      width: 30vw;
      height: 30vw;
      margin: 5vw; }
      .Voice_voice__1p2J9 .Voice_listeningMode__2n1JZ .Voice_microphone__myaHw .Voice_micImage__3vs-M {
        width: 20vw;
        height: 20vw; }

.DebugMode_debugMode__23b6Q {
  position: relative;
  height: calc(100vh - 60px);
  display: none;
  opacity: 0.8;
  z-index: 1000; }

.DebugMode_debugMode__23b6Q.DebugMode_enabled__3YMPm {
  display: block; }

.DebugMode_serverStatus__3sNc- {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  height: 20vh;
  padding: 10px 10px;
  text-align: left;
  background-color: rgba(255, 0, 0, 0.1);
  color: red; }

.DebugMode_output__2SaYp {
  display: flex;
  flex-wrap: wrap;
  width: 50vw;
  text-align: left;
  line-height: 1.5em;
  height: 20vh;
  overflow: auto;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px; }

.DebugMode_output__2SaYp .DebugMode_wordItem__2KVps {
  margin-right: 5px; }

.DebugMode_wordItem__2KVps.DebugMode_highlight__362ZN {
  color: #daa520;
  text-decoration: underline; }

.DebugMode_hypothesis__3SBhx {
  color: yellow;
  width: 50vw;
  text-align: left;
  height: 20vh;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  overflow: auto; }

.DebugMode_positions__2nOgP {
  position: fixed;
  top: 36px;
  right: 16px;
  text-align: left;
  line-height: 1.5em;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  padding: 16px;
  width: 40vw; }
  .DebugMode_positions__2nOgP .DebugMode_notification__1ru30 {
    font-size: 1.2em;
    color: red; }
  .DebugMode_positions__2nOgP .DebugMode_activeAudioItems__3kDKl {
    max-height: 30vh;
    overflow: auto; }
    .DebugMode_positions__2nOgP .DebugMode_activeAudioItems__3kDKl .DebugMode_cueitem__2Ic2n {
      font-size: 0.8em;
      -webkit-transition: 2s;
      transition: 2s; }
      .DebugMode_positions__2nOgP .DebugMode_activeAudioItems__3kDKl .DebugMode_cueitem__2Ic2n.DebugMode_fadeout__5kcij {
        opacity: 0.5;
        color: yellow; }

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.Mediascape_mediascape__3Etz3 {
  color: #fff;
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: #1a2c38; }

.Mediascape_foo__2NnSh {
  width: 10px; }

.Mediascape_bookText__2Q5Fo {
  width: 50vw;
  text-align: left;
  line-height: 1.5em;
  height: 20vh;
  overflow: auto; }

.Mediascape_loading__3ATJ4 {
  color: #fff;
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.Mediascape_gettingSpeechReady__2I60a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.Mediascape_menubar__1sTD6 {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.5); }

.Mediascape_videoSplash__2RMTj {
  background-color: #1a2c38;
  height: 100%;
  width: 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#4bb6c2), to(#1a2c38));
  background-image: -webkit-linear-gradient(#4bb6c2, #1a2c38);
  background-image: linear-gradient(#4bb6c2, #1a2c38);
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: contain;
  /* Resize the background image to cover the entire container */ }
  .Mediascape_videoSplash__2RMTj.Mediascape_hidden__EW5QA {
    display: none; }

.Mediascape_playButton__7zZzp {
  position: absolute;
  height: 12vw;
  width: 12vw;
  left: 50%;
  margin-left: -6vw;
  top: 50%;
  margin-top: -6vw; }

.Mediascape_waitforSpeech__2LLkF {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: #1a2c38; }

.Mediascape_prepText__uBuPk {
  font-size: 2em;
  margin-bottom: 2em; }

.Mediascape_fadeIn__2970W {
  -webkit-animation: Mediascape_fadein__3fF8d 3s;
          animation: Mediascape_fadein__3fF8d 3s;
  color: #fff;
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center; }

@-webkit-keyframes Mediascape_fadein__3fF8d {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes Mediascape_fadein__3fF8d {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.QrCode_qrcodeWrapper__3QLYA {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.QrCode_qrcodeTitle__1W3WT {
  width: 462px;
  height: 67px;
  font-family: NiveauGrotesk;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.QrCode_qrcodeWrap__1PFWT {
  display: flex;
  align-items: center;
  justify-content: center;
}

.QrCode_remoteUrl__3Zf43 {
  word-wrap: break-word;
  color: white;
}

@media screen and (max-width: 1920px) {
  .QrCode_qrcodeTitleAdditional__3win1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 960px) {
  .QrCode_qrcodeTitleAdditional__3win1 {
    font-size: 27px;
  }
}

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.FirstPlay_firstPlay__2I_v0 {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff; }
  .FirstPlay_firstPlay__2I_v0 .FirstPlay_textBlock__3jdBp {
    font-size: 1.5vw;
    width: 60vw;
    margin: 10px; }
  .FirstPlay_firstPlay__2I_v0 .FirstPlay_remoteImage__3731r {
    height: 45vh;
    width: auto;
    margin: 10px; }
  .FirstPlay_firstPlay__2I_v0 .FirstPlay_textContainer__10CKV {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    -webkit-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    z-index: 100; }
    .FirstPlay_firstPlay__2I_v0 .FirstPlay_textContainer__10CKV .FirstPlay_readingWords__1QQGC {
      position: relative;
      border-radius: 120px;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 1.5vw;
      width: 40vw;
      height: 4.3vw;
      display: flex;
      flex-wrap: wrap;
      font-size: 2.1vw;
      align-items: center;
      justify-content: center;
      line-height: 1.5em;
      text-shadow: 0px 0px 9px black;
      margin-bottom: 30px;
      margin-top: 30px; }
      .FirstPlay_firstPlay__2I_v0 .FirstPlay_textContainer__10CKV .FirstPlay_readingWords__1QQGC:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-top-color: rgba(0, 0, 0, 0.8);
        border-bottom: 0;
        margin-left: -20px;
        margin-bottom: -20px; }

@font-face {
  font-family: 'New Era Casual Bold Italic';
  src: url(/static/media/new_era_casual_bold_italic-webfont.e39bfb96.woff2) format('woff2'),
    url(/static/media/new_era_casual_bold_italic-webfont.601952b9.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Era Casual Bold';
  src: url(/static/media/new_era_casual_bold-webfont.b2464bed.woff2) format('woff2'), url(/static/media/new_era_casual_bold-webfont.c9f5c248.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Era Casual Italic';
  src: url(/static/media/new_era_casual_italic-webfont.5f98ed19.woff2) format('woff2'), url(/static/media/new_era_casual_italic-webfont.6e3a98ad.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Era Casual';
  src: url(/static/media/new_era_casual_regular-webfont.7d73bcea.woff2) format('woff2'), url(/static/media/new_era_casual_regular-webfont.eb65b1b3.woff) format('woff');
  font-weight: normal;
  font-style: normal;
} 

.App {
  text-align: center;
  background-color: #000;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/
@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
.App_App__15LM- {
  text-align: center;
  font-family: 'niveau-grotesk', sans-serif;
  background-color: #1a2c38;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row; }
  .App_App__15LM- .App_loadingWrap__1dazG {
    text-align: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center; }

