@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:100,300,400,400i,500,700');
@import url('https://use.typekit.net/lki7ztn.css');

//font-family: niveau-grotesk, sans-serif;

$color-black: #222;
$color-offblack: #16161d;
$color-white: #fff;
$color-offwhite: #f1f2f2;
$color-blue: #006ba4;
$color-blue-dark: #092234;
$color-blue-light: #2b8eaa;
$color-blue-bright: #00bfff;
$color-gray: #3f3f3f;
$color-gray-medium: #b0b4b8;
$color-gray-light: #e1e1e1;
$color-gray-lighter: #f8f9fb;
$color-red: #bf360c;
$color-orange: #ef6c00;
$color-yellow: #f4c20d;
$color-green: #1aaf4f;
$color-ne-green: #acd145;
$color-ne-orange: #f1873b;
$color-ne-blue: #1a2c38;
$color-ne-blue-light: #4bb6c2;
$color-ne-red: #e94c24;
$color-ne-grey: #e8e9e9;

$nfx-navy: #1a2c38;
$nfx-blue: #4bb6c2;
$nfx-red: #e94c24;
$nfx-green: #acd145;
$nfx-darkgrey: #939598;
$nfx-lightgrey: #e8e9e9;
$nfx-orange: #f1873b;
$nfx-white: #ffffff;
$nfx-black: #16161d;

$color-dark-divider: rgba(0, 0, 0, 0.2);

$model-gradient: linear-gradient(
  to bottom,
  $nfx-navy 0%,
  rgba(37, 61, 77, 0.5) 25%,
  rgba(37, 61, 77, 0.2) 40%,
  rgba(37, 61, 77, 0.2) 60%,
  rgba(37, 61, 77, 0.5) 75%,
  $nfx-navy 100%
);

/*
* Media Query Definitions
* To use: @media (--small-screen)
*/

@custom-media --x-small-screen (max-width: 520px);
@custom-media --small-screen (max-width: 720px);
@custom-media --medium-screen (max-width: 900px);
