@import '../../styles/variables.scss';

.waitMode {
  color: $nfx-white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  font-size: 3em;

  & div {
    margin: 0 10vw;
  }
}
