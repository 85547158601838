@import '../../styles/variables.scss';

.fullscreenMode {
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 0 16px;

  &:hover {
    & .fullScreenIcon {
      stroke: $nfx-lightgrey;
    }
  }

  & .fullScreenIcon {
    fill: none;
    stroke: $nfx-white;
    stroke-linecap: round;
    stroke-width: 3px;
    stroke-linejoin: round;
  }
}
