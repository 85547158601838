@import '../../styles/variables.scss';
/* ==========  Select Field Variables ========== */
:root {
  --color-black: #000;
  --select-background-color: transparent;
  --select-border-color: rgba(0, 0, 0, 0.12);
  --select-active-border-color: rgba(0, 162, 255, 1);
  --select-hover-border-color: rgba(0, 162, 255, 0.8);
  --select-font-size: 14px;
  --select-color: rgba(0, 0, 0, 0.26);
  --select-padding: 4px;
}

/* ==========  Select Field ========== */

.mdlSelectfield {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  position: relative;

  & label {
    display: none;
  }
  & .nfxSelect {
    appearance: none;
  }
  &:after {
    position: absolute;
    top: 0.75em;
    right: 0.5em;
    /* Styling the down arrow */
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: 0.25em solid transparent;
    border-right: 0.25em solid transparent;
    border-top: 0.375em solid var(--select-background-color);
    pointer-events: none;
  }

  /* Style Select Field */
  & .nfxSelect {
    font-family: inherit;
    background-color: rgba(255, 255, 255, 0.6);
    width: 100%;
    padding: var(--select-padding) 0;
    font-size: var(--select-font-size);
    color: var(--color-black);
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--select-border-color);

    &:hover {
      border-bottom: 1px solid var(--select-hover-border-color);
    }

    &:focus {
      outline: none;
      border-bottom: 1px solid var(--select-active-border-color);
    }
  }

  & .size--small {
    & .nfxSelect {
      padding: 0;
      font-size: 0.8em;
    }
  }
}
