.currentId {
  color: #f0f0f0;
  display: none;
}
.videoWrapper {
  height: 100%;
  // display: flex;
}
.videoContainer {
  z-index: 50;
  width: 100vw;

  &.hidden {
    display: none;
  }

  .urlText {
    position: absolute;
    top: 5px;
    left: 3px;
    right: 3px;
    color: #fff;
    font-size: 9pt;
    word-wrap: break-word;
    text-align: left;
    display: none;
  }
}

.videostatus {
  display: flex;
}
.statusItem {
  color: #f0f0f0;
  opacity: 1;
  transition: opacity 500ms;
  padding: 10px 10px;
  display: inline;

  &.hidden {
    opacity: 0.5;
  }
}
