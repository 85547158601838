@import '../../styles/variables.scss';

.bottomBar {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 60px;
  background-color: $nfx-navy;
  z-index: 10000;
  opacity: 0.8;
  transition: 0.5s;
  overflow: hidden;
  padding-top: 6px;

  &.hidden {
    // height: 0px;
    bottom: -60px;
    opacity: 0;
  }

  & .centerOptions {
    position: absolute;
    left: 50%;
    right: 50%;
    width: 60px;
    margin: auto;
    margin-left: -30px;
  }

  & .items {
    padding: 10px;
    width: calc(100vw -20px);
    display: flex;
    justify-content: space-between;
  }

  & .back {
    cursor: pointer;
  }

  & .rightOptions {
    display: flex;
  }

  & .exitButton {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-width: 3px;
  }
}
