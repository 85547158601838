@import '../../styles/variables.scss';

.loginPage {
  color: var(--color-ne-blue);

  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginbox {
  background-color: rgba(255, 255, 255, 1);
  padding: 10px 30px;
  border-radius: 10px;
}

.facebookButton {
  padding: 10px 0;
}
