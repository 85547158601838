@import '../../styles/variables.scss';

.microphoneButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  .microphoneIcon {
    & .micOn {
      fill: $nfx-blue;
    }

    & .micOff {
      fill: $nfx-darkgrey;
    }

    & .redLine {
      fill: $nfx-red;
    }
  }
  & .visualizer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;

    & .small {
      margin: 0 2px;
      width: 4px;
      height: 2px;
      background-color: $nfx-blue;
      border-radius: 30%;
      // transition: height random(500) + ms ease-in-out;

      &.one {
        &.active {
          height: 10px;
          animation: bouncy random(200) + 300 + ms ease alternate infinite;
        }
      }

      &.two {
        &.active {
          height: 10px;
          animation: bouncy random(300) + 300 + ms ease alternate infinite;
        }
      }

      &.three {
        &.active {
          height: 10px;
          animation: bouncy random(200) + 300 + ms ease alternate infinite;
        }
      }

      &.four {
        &.active {
          height: 10px;
          animation: bouncy random(200) + 300 + ms ease alternate infinite;
        }
      }
    }

    & .large {
      margin: 0 2px;
      width: 6px;
      height: 2px;
      background-color: $nfx-blue;
      border-radius: 30%;
      transition: height 1s ease-in-out;

      &.active {
        height: 15px;
      }
    }
  }
}

@keyframes bouncy {
  0% {
    height: 1px;
  }
  50% {
    height: 10px;
  }
  100% {
    height: 1px;
  }
}
