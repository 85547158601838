@import '../../styles/variables.scss';

.firstPlay {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $nfx-white;

  & .textBlock {
    font-size: 1.5vw;
    width: 60vw;
    margin: 10px;
  }

  & .remoteImage {
    height: 45vh;
    width: auto;
    margin: 10px;
  }

  .textContainer {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 1s ease-in-out;
    z-index: 100;

    // transition: all 10s;

    & .readingWords {
      position: relative;
      border-radius: 120px;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 1.5vw;
      width: 40vw;
      height: 4.3vw;
      display: flex;
      flex-wrap: wrap;
      font-size: 2.1vw;
      align-items: center;
      justify-content: center;

      line-height: 1.5em;
      text-shadow: 0px 0px 9px rgba(0, 0, 0, 1);

      margin-bottom: 30px;
      margin-top: 30px;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-top-color: rgba(0, 0, 0, 0.8);
        border-bottom: 0;
        margin-left: -20px;
        margin-bottom: -20px;
      }
    }
  }
}
