@import './styles/variables.scss';

.App {
  text-align: center;
  font-family: 'niveau-grotesk', sans-serif;
  background-color: $nfx-navy;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;

  & .loadingWrap {
    text-align: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    & .loading {
    }
  }
}
