@import '../../styles/variables.scss';

.Content {
  background-color: $nfx-navy;
  min-height: calc(100vh + 120px);
  padding: 20px;
  margin: 0;
}

.resourceList {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  color: $nfx-white;
  flex-flow: row nowrap;
  overflow: auto;
}

.contentItem {
  width: 36vw;
  // min-width: 160px;
  min-height: 200px;
  // height: 20vw;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 20px;
  transition: 500ms;
  border-radius: 5px;
  opacity: 0.7;

  &:hover {
    background-color: rgba(75, 182, 194, 0.8);
    cursor: pointer;
    opacity: 1;
  }

  &.selected {
    background-color: rgba(75, 182, 194, 0.8);
    opacity: 1;
  }
}

.imageContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 35vw;
  min-width: 150px;
  height: auto;
  min-height: 150px;
  overflow: hidden;
}

.mediaImage {
  width: 100%;
  height: auto;
}

.title {
  line-height: 2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loadingWrap {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
