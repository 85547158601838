@import '../../styles/variables.scss';
.soundscape {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  padding: 5vw;
  margin: 0vw;
  background-color: $nfx-navy;

  font-size: 2vw;

  & h1 {
    margin: 0;
    margin-bottom: 1em;
  }
  & img {
    width: 50%;
  }

  & .authors {
    margin: 1em 0;
  }

  & .description {
    margin: 1em 0;
  }

  & .copyright {
    font-size: 0.7em;
    margin: 1em 0;
  }

  & a {
    color: $nfx-blue;
    text-decoration: none;
  }
}
