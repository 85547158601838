.debugMode {
  position: relative;
  height: calc(100vh - 60px);
  display: none;
  opacity: 0.8;
  z-index: 1000;
}

.debugMode.enabled {
  display: block;
}

.serverStatus {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  height: 20vh;
  padding: 10px 10px;
  text-align: left;
  background-color: rgba(255, 0, 0, 0.1);
  color: rgba(255, 0, 0, 1);
}

.output {
  display: flex;
  flex-wrap: wrap;
  width: 50vw;
  text-align: left;
  line-height: 1.5em;
  height: 20vh;
  overflow: auto;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
}

.output .wordItem {
  margin-right: 5px;
}

.wordItem.highlight {
  color: #daa520;
  text-decoration: underline;
}

.hypothesis {
  color: yellow;
  width: 50vw;
  text-align: left;
  height: 20vh;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  overflow: auto;
}

.positions {
  position: fixed;
  top: 36px;
  right: 16px;
  text-align: left;
  line-height: 1.5em;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  padding: 16px;
  width: 40vw;

  & .notification {
    font-size: 1.2em;
    color: rgba(255, 0, 0, 1);
  }

  & .activeAudioItems {
    max-height: 30vh;
    overflow: auto;
    & .cueitem {
      font-size: 0.8em;
      transition: 2s;

      &.fadeout {
        opacity: 0.5;
        color: yellow;
      }
    }
  }
}
