@import '../../styles/variables.scss';

.videoManager {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: $nfx-white;
}

.videoMediaScape {
  height: 100%;
}

.completedMenu {
  position: fixed;
  font-size: 3em;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: $nfx-white;
  display: none;
  opacity: 0;
  transition: opacity 2s;
  z-index: 185;

  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
  }

  & .completedButton {
    margin: 10px;
    border: 2px solid $nfx-white;
    background: $nfx-navy;
    border-radius: 10px;
    padding: 10px 40px;
    transition: 0.25s;
    cursor: pointer;

    &.active {
      border: 2px solid $nfx-white;
      background: $nfx-white;
      color: $nfx-navy;
    }

    &:hover {
      border: 2px solid $nfx-white;
      background: $nfx-white;
      color: $nfx-navy;
    }
  }
}
