@import '../../styles/variables.scss';

.playControls {
  display: flex;
  align-items: center;
  justify-content: center;

  .skip {
    cursor: pointer;

    &:hover {
      & .skipButton {
        fill: $nfx-blue;
        stroke: $nfx-blue;
      }
    }

    & .skipButton {
      fill: $nfx-white;
      stroke: $nfx-white;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 3px;
      transition: 0.25s;
    }
  }

  .playpause {
    padding: 0 16px;
    cursor: pointer;

    &:hover {
      & .playPauseButton {
        fill: $nfx-blue;
        stroke: $nfx-blue;
      }
    }

    & .playPauseButton {
      fill: $nfx-white;
      stroke: $nfx-white;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 3px;
      transition: 0.25s;
    }
  }
}
