@import '../../styles/variables.scss';

.branding {
  position: fixed;
  top: 20px;
  left: 20px;

  & .logo {
    width: 10vw;
    height: auto;
    padding: 0;
  }

  & .subtext {
    color: $nfx-white;
    font-size: 1.3em;
  }
}
