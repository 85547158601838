.buttonContainer {
  cursor: pointer;
}

.startButton {
  & .clsOne {
    fill: rgba(26, 44, 56, 0.5);
    stroke: rgba(112, 112, 112, 0.5);
    transition: 0.2s;
    cursor: pointer;
  }

  & .clsTwo {
    fill: rgba(255, 255, 255, 0.8);
    stroke: rgba(255, 255, 255, 0.8);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-width: 3px;
    transition: 0.2s;
  }

  & .clsThree {
    stroke: none;
  }

  & .clsFour {
    fill: none;
  }

  &:hover {
    & .clsOne {
      fill: rgba(26, 44, 56, 0.8);
      stroke: rgba(112, 112, 112, 0.8);
    }

    & .clsTwo {
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(255, 255, 255, 0.8);
    }
  }
}
