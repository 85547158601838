@import '../../styles/variables.scss';
.textPlacement {
  // position: fixed;
  // left: 0;
  // right: 0;
  margin-left: auto;
  margin-right: auto;
}

.TextContainer {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;

  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: 100;
  backdrop-filter: blur(50px);
  // transition: all 10s;

  &.shelvesVisible {
    bottom: 80px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-top-color: rgba(0, 0, 0, 0.4);
      border-bottom: 0;
      margin-left: -20px;
      margin-bottom: -20px;
    }
  }

  &.visible {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
}

.TextContainer .readingWords {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.TextContainer.visible .readingWords {
  opacity: 1;
}

.TextContainer.enchant {
  font-family: 'New Era Casual';
}

.TextGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
  width: 100%;
}
.readingWords {
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  padding: 1.5vw;
  width: 40vw;
  height: 4.3vw;
  display: flex;
  flex-wrap: wrap;
  font-size: 2.1vw;

  line-height: 1.5em;
  text-shadow: 0px 0px 9px rgba(0, 0, 0, 1);

  margin-bottom: 0;

  transition: all 600ms ease-out;
  transition: opacity 0.3s ease-out;

  // box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.9);

  & .wordItem {
    margin-right: 0.8vw;
    transition: all 0.1s;

    &.activeWord {
      color: lighten(#ffe500, 40%);
      // text-shadow: 0px 0px 9px rgba(255, 229, 0, 0.5);
      text-shadow: -2px -2px 5px $color-ne-blue-light, 2px -2px 5px $color-ne-blue-light, -2px 2px 5px $color-ne-blue-light, 2px 2px 5px $color-ne-blue-light;
    }

    &.nextWord {
      text-shadow: 0px 0px 9px rgba(0, 0, 0, 1);
    }

    &.readWord {
      text-shadow: -2px -2px 5px $color-ne-blue-light, 2px -2px 5px $color-ne-blue-light, -2px 2px 5px $color-ne-blue-light, 2px 2px 5px $color-ne-blue-light;
    }

    &.usesSeparator {
      margin-right: 0;
    }
  }
}
