.UnityLoading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #222;
  color: #f0f0f0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FullBar {
  position: relative;
  width: 80vw;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.LoadingAmount {
  border-radius: 30px;
  color: #fff !important;
  height: 5vh;
  background-color: rgba(255, 255, 255, 0.8) !important;
  justify-content: left;
}

.LoadingNumber {
  color: #222;
  position: absolute;
  left: 16px;
  top: 16px;
  font-size: 2vh;
}
