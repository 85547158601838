@import '../../styles/variables.scss';

.voice {
  text-align: center;
  color: $nfx-white;
  font-size: 5vw;
  height: 100vh;
  width: 100vw;
  display: flex;

  justify-content: center;

  & .loading {
    margin: 10vh;
  }

  & .listeningMode {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & .NFXlogo {
      width: 30vw;
      height: auto;
    }

    & .microphone {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $nfx-white;
      border-radius: 100%;
      width: 30vw;
      height: 30vw;
      margin: 5vw;

      & .micImage {
        width: 20vw;
        height: 20vw;
      }
    }
  }
}
