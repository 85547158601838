@import '../../styles/variables.scss';

.speakerIcon {
  fill: $nfx-white;
}

.nopeBar {
  fill: $nfx-red;
}
.mutedIcon {
  fill: $nfx-darkgrey;
}
