@import '../../styles/variables.scss';

.debugMode {
  width: 33px;
  height: 33px;

  &:hover {
    & .debugIcon {
      fill: $nfx-lightgrey;
    }
  }

  & .debugIcon {
    fill: $nfx-white;

    &.active {
      fill: $nfx-blue;
    }
  }
}
