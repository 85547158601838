@import '../../styles/variables.scss';

.Pairing {
  background-color: $nfx-navy;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  display: flex;

  justify-content: center;
  color: $nfx-white;

  & .NFXlogoContainer {
    padding: 50px;
    margin: 10px;
    text-align: center;

    & .NFXlogo {
      /* animation: App-logo-spin infinite 50s linear; */
      width: 50vw;
    }
  }

  & .helpText {
    margin: 16px;
    font-size: 2.5vw;
  }

  & .inputContainer {
    margin-top: 20vh;
    font-size: 2.5vw;

    & .inputBox {
      width: 50vw;

      & input {
        font-size: 1.2em;
        color: $nfx-white;
        text-align: center;
        background-color: $nfx-navy;
        border: 1px solid $nfx-white;
        border-radius: 2px;
      }
    }
  }
}
